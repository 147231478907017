import React, {useEffect, useState} from 'react';
import {Button, Dropdown, DropdownButton, Form, Modal, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {IPassword, IUser} from '../../../model/user/IUser';
import {UserService} from '../UserService';
import {Pagination} from '../../../shared/components/Pagination';
import {UserFilter} from './UserFilter';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router';
import {ErrorToast, SuccessToast, WarningToast} from '../../../utils/toasters';
import {formatDate} from '../../../utils/Functions';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {PasswordVerifySteps} from '../../../shared/components/PasswordVerifySteps';
import Flag from 'react-world-flags';
import {getCountryName} from "../../../helpers/CorrectCountryNames";
import {AiFillEye, AiFillEyeInvisible, ImMobile} from 'react-icons/all';

const UserList = () => {
    let history = useHistory();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [passState, setPassState] = useState<IPassword>({
        password: '',
        repeatPassword: ''
    });
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        displayName: '',
        email: '',
        firstName: '',
        lastName: '',
        type: ''
    })
    const [selectedUser, setSelectedUser] = useState<IUser | null>();
    const [user, setUser] = useState<IUser[]>([]);
    const [change, setChange] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [isPasswordHidden, setIsPasswordHidden] = useState(false);

    useEffect(() => {
        UserService.getUsers(state)
            .then((data) => {
                setUser(data.data);
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }, [change]);

    function disableUsers(id: number) {
        UserService.disableUser(id)
            .then((data) => {
                const updatedUsers = user.map((prev) => {
                    if(prev?.id! === id) return data
                    return prev
                })
                setUser(updatedUsers);
                const status = !!data.isEnabled ? 'enabled' : 'disabled';
                SuccessToast('Successfully '+status+' user')
            })
            .catch((err) => ErrorToast(err));
    }

    // const toggleOnboarding = (id: number) => {
    //     UserService.onboardUser(id)
    //         .then((data) => {
    //             const updatedUsers = user.map((prev) => {
    //                 if(prev?.id! === id) return data
    //                 return prev
    //             })
    //             setUser(updatedUsers);
    //             SuccessToast('Successful action')
    //         })
    //         .catch((err) => ErrorToast(err));
    // }

    function suspendUser(id: number) {
        UserService.suspendUser(id)
            .then((data) => {
                const updatedUsers = user.map((prev) => {
                    if(prev?.id! === id) return data
                    return prev
                })
                setUser(updatedUsers);
                SuccessToast('Successful action')
            })
            .catch((err) => ErrorToast(err));
    }

    function passData(route: string, data?: any) {
        history.push({
            pathname: route,
            state: data
        })
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage});
        setChange((prev) => !prev);
    };

    function handleClick() {
        setState((prev) => {return {...prev, page: 1}})
        setChange((prev) => !prev);
    }

    function handleChange(evt: React.ChangeEvent<HTMLInputElement>, isSelectBoolean?: boolean) {
        const {name, value} = evt.target;
        let newValue: any = value?.replace(/^@/, '');
        if ( isSelectBoolean ) {
            if ( value === "null" ) {
                newValue = undefined;
            } else newValue = value === "enable"
        }
        setState({
            ...state,
            [name]: typeof newValue === 'string'  ? newValue?.trim() : newValue // remove @ on first place
        });
    }

    const changePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setPassState(prev => ({...prev, [name]: value}))
    }

    const handleCloseModal = () => {
        setShowChangePassword(false)
        setSelectedUser(null)
    }

    const submitPasswordHandler = async () => {
        if (passState?.password === passState?.repeatPassword) {
            await UserService.changePassword(selectedUser?.id as number, passState).then(response => {
                if (response?.id) {
                    SuccessToast(`You have successfully changed password for user ID ${response?.id}`)
                    handleCloseModal();
                }
            }).catch(error => ErrorToast(error));
        } else {
            WarningToast('Provided passwords are not the same')
        }
    }

    const editSupervisor = (id: number) => {
        UserService.updateSupervisor(id)
        .then((data) => {
            const updatedUsers = user.map((prev) => {
                if(prev?.id! === id) return data
                return prev
            })
            setUser(updatedUsers);
            SuccessToast('Successfully action')
        })
        .catch((err) => ErrorToast(err));
    }

    return (
        <>
            <h3 className="text-dark fw-bold fs-2 mb-4">Customer List</h3>

            <div className="card card-custom mb-5 p-4" onKeyDown={(e) => {if(e.key === 'Enter') handleClick()}}>
                <UserFilter
                    handleChanges={handleChange}
                    handleClicks={handleClick}
                    allUsers={true}
                />
            </div>

            <div className="card card-custom mb-5 p-4">
                <Table responsive className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr className="even">
                            <th>ID</th>
                            <th>Role</th>
                            <th>Display name</th>
                            <th>Country</th>
                            <th>Email</th>
                            <th>Last login</th>
                            <th>Is Supervisor?</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {user?.map((e) => (
                        <tr key={e.id}>
                            <td className={!e.isEnabled ? 'text-danger': ''}>{e.id}</td>
                            <td>{e.userType ?? '-'}</td>
                            <td>
                                {e.displayName && (
                                    <>
                                    {e.displayName}
                                    <br/>
                                    </>
                                )} 
                                {e.firstName ?? '-'} {e.lastName}
                            </td>
                            <td><Flag className='country-flag cursor-pointer' code={e?.contact?.country} title={getCountryName(e.contact.country)}/></td>
                            <td><ImpersonateEye id={e.id ?? 0}/>{e.email}</td>
                            <td>
                                {formatDate(e.lastLogin as string) ?? '-'}<br/> IP:{e.lastLoginIp ?? '-'}
                                 {e.hasMobileLogin && (
                                    <OverlayTrigger placement="top"
                                                    overlay={<Tooltip
                                                        id="impressions-tooltip">
                                                        {e?.mobileDate && formatDate(e?.mobileDate)}
                                                    </Tooltip>}>
                                        <ImMobile size={18} className="cursor-pointer ml-1"/>
                                    </OverlayTrigger>
                                )}   
                            </td>
                            <td>
                                {(e?.userType === 'brand' || e?.userType === 'brand_master' ) ? (

                                    <input type="checkbox" className='form-check-input' defaultChecked={e.isSupervisor}
                                    data-toggle="toggle"
                                    onChange={(event) => {
                                        editSupervisor(e?.id!)
                                    }}
                                    />
                                ) : '-'}
                            </td>
                            <td>
                                <DropdownButton title="Actions" id="dropdown-menu-align-right">
                                    <Dropdown.Item eventKey="1" onClick={() => passData(`/user/edit/${e.id}/true`)}>
                                        Edit
                                    </Dropdown.Item>
                                    {(e.userType === 'influencer') && (
                                        <Dropdown.Item eventKey="2" onClick={() => passData('/user/devices', {user:e})}>
                                            Devices
                                        </Dropdown.Item>
                                    )}
                                    {(e.userType === 'influencer') && (
                                        <Dropdown.Item eventKey="2" onClick={() => passData('/user/platforms', {id: e.id, user: e})}>
                                            Edit platforms
                                        </Dropdown.Item> 
                                    )}
                                    <Dropdown.Item eventKey="3" onClick={() => {
                                        setShowChangePassword(true)
                                        setSelectedUser(e);
                                    }}>
                                        Change password
                                    </Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={() => disableUsers(e.id ?? 0)}
                                                   eventKey="3">{e.isEnabled ? 'Disable' : 'Enable'}</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={() => suspendUser(e.id ?? 0)}
                                                   eventKey="4">{e.isSuspended ? 'Lift suspension' : 'Suspend'}</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    {user.length < 15 && !!user.length && (
                        <div style={{height: 220}}></div>
                    )}
                </Table>
                {totalPages > 1 &&
                    <div className="w-100">
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    </div>
                }
            </div>

            <Modal
                show={showChangePassword}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change password #{selectedUser?.id}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>New password<span className="text-danger">*</span></Form.Label>
                        <div className="d-flex align-items-center">
                            <Form.Control as="input" onChange={changePasswordHandler} value={passState?.password ?? ''}
                                          type={!isPasswordHidden ? 'password' : 'text'}
                                          name="password"/>
                            <span style={{marginLeft: '-2rem'}} className="cursor-pointer"
                                    onClick={() => setIsPasswordHidden(prev => !prev)}>
                                {isPasswordHidden ? <AiFillEye size={15}/> : <AiFillEyeInvisible size={15}/>}
                            </span>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Repeat password<span className="text-danger">*</span></Form.Label>
                        <Form.Control as="input" onChange={changePasswordHandler}
                                      value={passState?.repeatPassword ?? ''} type="password"
                                      name="repeatPassword"/>
                    </Form.Group>
                    <PasswordVerifySteps password={passState?.password}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">Close</Button>
                    <Button variant="primary" onClick={submitPasswordHandler}
                            disabled={passState?.password?.length < 7}>Change</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserList;
