import React, {useEffect, useState} from 'react';
import {Card, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {Company} from '../../../model/company/Company';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {Pagination} from '../../../shared/components/Pagination';
import {getCorrectSocialUrl} from '../../../utils/Functions';
import {CompanyService} from '../../company/CompanyService';
import {OnboardingService} from '../OnboardingService';
import OnboardingFilters from './OnboardingFilters';
import {IUser} from "../../../model/user/IUser";
import {Platform} from "../../../model/platform/Platform";
import {ErrorToast, SuccessToast} from "../../../utils/toasters";
import {Campaign} from "../../../model/campaign/Campaign";
import {FiInfo} from "react-icons/all";
import {FaCheck, FaTimes} from "react-icons/fa";
import {CustomImageHandler} from "../../../helpers/CustomImageHandler";

interface IRequest {
    id: number;
    fullName: string;
    email: string;
    companyId: number;
    isOnboarded: boolean;
    isContacted: boolean;
    photoUrl: string;
    displayName: string;
    requestedBy: string;
    title: string;
    internalUser: IUser;
    mainPlatform: Platform;
    externalLink: string;
    campaign: Campaign;
}

const OnboardingRequestList = () => {
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
        handle: '',
        companyId: null,
    });
    const [company, setCompany] = useState<Company[]>([]);
    const [requestedList, setRequestedList] = useState<IRequest[]>([])
    const [totalPages, setTotalPages] = useState(0);

    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
    };

    useEffect(() => {
        CompanyService.getCompanies({page: 1, perPage: 10, hasOnboardingRequests: true})
            .then((data) => {
                setCompany(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`);
            });
    }, [])

    useEffect(() => {
        OnboardingService.getAllRequests(pagination).then((data) => {
            setRequestedList(data?.data)
            setTotalPages(Math.ceil(data.count / pagination.perPage));
        })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`);
            });
    }, [pagination])

    const editIsContacted = (id: number) => {
        OnboardingService.updateIsContacted(id)
            .then((data) => {
                const updatedReqs = requestedList.map((prev) => {
                    if(prev?.id! === id) return data
                    return prev
                })
                setRequestedList(updatedReqs);
                SuccessToast('Successfully action')
            })
            .catch((err) => ErrorToast(err));
    }

    return (
        <>
            <OnboardingFilters company={company}
                               setPagination={setPagination}/>
            <Card>
                <Card.Body>
                    <Table hover responsive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Photo</th>
                            <th>Platform</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>isContacted?</th>
                            <th>Handle</th>
                            <th>Requested by</th>
                            <th>Campaign</th>
                            <th>isOnboarded</th>
                            <th>Onboarded user id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {requestedList?.map(request => {
                            return (
                                <tr key={request.id}>
                                    <td>{request?.id}</td>
                                    <td>
                                        <CustomImageHandler
                                            altTag={''}
                                            classes="rounded-circle"
                                            thumbnailPath={request?.photoUrl ?? ''}
                                            photoPath={request?.photoUrl ?? ''}
                                            style={{width: 50, height: 50}}
                                        />
                                    </td>
                                    <td>
                                        {request?.mainPlatform?.path ? <img alt='' src={request?.mainPlatform?.path} style={{width: 35, height: 35}}/>
                                        : <img alt='' src="/images/placeholder.png" style={{width: 35, height: 35}}/>
                                    }</td>
                                    <td>{request?.fullName ?? '-'}</td>
                                    <td>{request?.email ?? '-'}</td>
                                    <td>
                                        <input type="checkbox" className='form-check-input' defaultChecked={request.isContacted}
                                               data-toggle="toggle"
                                               onChange={(event) => {
                                                   editIsContacted(request?.id!)
                                               }}
                                        />
                                    </td>
                                    <td>
                                        <a target="_blank"
                                            className="d-flex align-items-center mb-2 mr-3"
                                            href={`${getCorrectSocialUrl(request?.mainPlatform?.platformEnum as string, request?.title, request?.externalLink)}`}
                                            rel="noreferrer">
                                                <span className="ml-1 mb-0">
                                                    @{request?.title ?? '-'}
                                                </span>
                                        </a>
                                    </td>
                                    <td>{request?.requestedBy ?? '-'}</td>
                                    <td>{request?.campaign?.id}
                                        {request?.campaign?.id && <OverlayTrigger placement="top"
                                                        overlay={<Tooltip
                                                            id="campaign-tooltip">
                                                            {request?.campaign?.title}
                                                        </Tooltip>}>
                                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                                        </OverlayTrigger>}
                                    </td>
                                    <td>{request?.isOnboarded ? <span className="word-break text-primary"><FaCheck/></span> :
                                        <span className="word-break text-danger"><FaTimes/></span>}</td>
                                    <td>{!!request?.internalUser?.id && (
                                        <div>
                                            <ImpersonateEye id={request?.internalUser?.id}/>{request?.internalUser?.id}
                                        </div>
                                    )}
                                    </td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                    <div className="w-100">
                        {totalPages > 1 &&
                            <Pagination
                                page={pagination.page}
                                totalPages={totalPages}
                                handlePagination={handlePages}
                            />
                        }
                    </div>
                </Card.Body>
            </Card>
        </>

    );
};

export default OnboardingRequestList;
