import {requests} from '../../services';
import {BaseModel} from '../../model/shared/BaseModel';
import {COMPANY_URL} from "../../utils/Endpoints";
import {Company} from "../../model/company/Company";
import {IUser} from '../../model/user/IUser';

export const CompanyService = {
    getCompanies: (params: any): Promise<BaseModel<Company>> => requests.get(`${COMPANY_URL}`,params),
    getCompany: (id: number): Promise<Company> => requests.get(`${COMPANY_URL}/${id}`),
    getCompanyUsers: (id: number): Promise<IUser[]> => requests.get(`${COMPANY_URL}/${id}/users`),
    getCompanyPartners: (params: any): Promise<{data: Company[]}> => requests.get(`${COMPANY_URL}/partners/companies`, params),
    postCompanyPartner: (params: any): Promise<IUser[]> => requests.post(`${COMPANY_URL}/partners/assign`, params),
    getCompanyPartnersAssigned: (params: any): Promise<{data: Company[]}> => requests.get(`${COMPANY_URL}/partners/shared`, params),
    deleteCompanyPartner: (clientId: number, supplierId: number): Promise<Company[]> => requests.delete(`${COMPANY_URL}/partners/client/${clientId}/supplier/${supplierId}`),
    deleteCompany: (id: number): Promise<Company[]> => requests.delete(`${COMPANY_URL}/${id}`),
    toggleClientLight: (id:number, params?: any): Promise<Company[]> => requests.put(`${COMPANY_URL}/partners/toggle/light/${id}`, params),
    // disableUser: (id: number) => requests.delete(`${COMPANY_URL}/${id}`)
}
