import {IUser} from '../../model/user/IUser';
import {requests} from '../../services';
import { LIST_AGENCY_URL } from '../../utils/Endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {IPendingEmails} from './dto/IPandingEmails';

export const AgencyService = {
    getAgencies: (searchFunction: any): Promise<BaseModel<IUser>> => requests.get(`${LIST_AGENCY_URL}`,searchFunction),
    disableUser:(id: number): Promise<IUser> => requests.put(`${LIST_AGENCY_URL}/${id}/disable`, {}),
    getInfluencers:(id: number) => requests.get(`${LIST_AGENCY_URL}/${id}/influencers`),
    getManagers:(id: number) => requests.get(`${LIST_AGENCY_URL}/${id}/managers`),
    getPendingEmails:(id: number): Promise<IPendingEmails[]> => requests.get(`${LIST_AGENCY_URL}/pending/influencers/${id}`),
    deletePendingEmails:(id: number): Promise<IPendingEmails[]> => requests.delete(`${LIST_AGENCY_URL}/pending/influencers/${id}`),
    getAgency: (id: number): Promise<IUser> => requests.get(`${LIST_AGENCY_URL}/${id}`),
    editAgency: (id: number, user: Partial<IUser>): Promise<IUser> => requests.put(`${LIST_AGENCY_URL}/${id}`, user),
    createAgency: (user: Partial<IUser>): Promise<IUser> => requests.post(LIST_AGENCY_URL, user),
    removeInfluencer:(id: number): Promise<any> => requests.delete(`${LIST_AGENCY_URL}/remove/agency/influencer/${id}`),

}
