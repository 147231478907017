import React, { useEffect, useState } from "react";
import { IPhoto } from "../../model/shared/IPhoto";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Carousel } from "react-bootstrap";

interface CustomFancyboxProps {
  images: IPhoto[];
}

const CustomFancybox = ({ images }: CustomFancyboxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openModal = (index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => setIsOpen(false);

  const nextImage = () => {
    if (photoIndex+1 <= images.length-1) {
        setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  }
  
  const prevImage = () => {    
    if (photoIndex - 1 >= 0) {
        setPhotoIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
}
  
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {       
        if (event.key === "Escape") {
            closeModal();
        } else if (event.key === "ArrowLeft") {
            prevImage();
        } else if (event.key === "ArrowRight") {
            nextImage();
        }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, photoIndex]);

  return (
    <div className="fancybox">
      <div >
        <Carousel 
            className='custom-carousel-indicators px-19'
            controls={images?.length > 1}
            indicators={images?.length > 1}
            activeIndex={photoIndex}
            onSelect={(e) => setPhotoIndex(e)}
            interval={null}
            nextIcon={
                <div className="icon-circle">
                    <FaArrowRight style={{ fontSize: '2rem' }} />
                </div>
            }
            prevIcon={
                <div className="icon-circle">
                    <FaArrowLeft style={{ fontSize: '2rem' }} />
                </div>
            }
        >
        {images?.length ? (
                images.map((item, imgIndex) => (
                    <Carousel.Item key={item.reportPath+''+item.id} className="text-center cursor-pointer">
                        <img
                            alt='screenshot'
                            src={item?.reportPath}
                            onClick={() => openModal(imgIndex)}
                            style={{ maxWidth: '100%', height: 'auto', maxHeight: 700,  }}
                            className="rounded"
                        />
                    </Carousel.Item>
                ))
            ) : (
                <img
                    alt=''
                    src="/images/placeholder.png"
                    style={{ maxWidth: '100%', height: 'auto' }}
                    className="rounded"
                />
        )}
        </Carousel>
      </div>

      {images[photoIndex] && (
        <div
          className={`fancybox-modal ${isOpen ? 'show' : ''}`}
          onClick={closeModal}
        >
            <div className="holder-fullscreen">
                <img
                    src={images[photoIndex].reportPath}
                    alt="Fullscreen"
                    className="rounded w-100 h-100"
                    onClick={(e) => e.stopPropagation()}
                />
            </div>

            <div className="d-flex mt-5 mb-2 gap-2 align-items-center">
                {images?.length ? (
                        images.map((item, imgIndex) => (
                            <div key={item.reportPath+''+item.id} className="text-center cursor-pointer"  onClick={(e) => {
                                e.stopPropagation();
                
                            }}>
                                <img
                                    alt='screenshot'
                                    src={item?.reportPath}
                                    onClick={() => openModal(imgIndex)}
                                    style={{ height: photoIndex === imgIndex ? 110 : 100 , width:  photoIndex === imgIndex ? 110 : 100, maxHeight: 700,  }}
                                    className={`rounded img-cover fancybox-gallery-img ${ photoIndex === imgIndex ? 'indexed' : ''}`}
                                />
                            </div>
                        ))
                    ) : (
                        <img
                            alt='placeholder'
                            src="/images/placeholder.png"
                            style={{ maxWidth: '100%', height: 'auto' }}
                            className="rounded"
                        />
                )}
            </div>

            {photoIndex > 0 && (
                <button
                    className="btn position-absolute cursor-pointer text-white"
                    onClick={(e) => {
                        e.stopPropagation();
                        prevImage();
                    }}
                    style={{
                        left: "5%",
                        fontSize: "2rem",
                    }}
                >
                    <div className="icon-circle">
                        <FaArrowLeft style={{ fontSize: '2rem' }} />
                    </div>
                </button>
            )}
            {photoIndex < images.length-1 && (
                <button
                    className="btn position-absolute cursor-pointer text-white"
                    onClick={(e) => {
                        e.stopPropagation();
                        nextImage();
                    }}
                    style={{
                        right: "5%",
                        fontSize: "2rem",
                    }}
                >
                    <div className="icon-circle">
                        <FaArrowRight style={{ fontSize: '2rem' }} />
                    </div>
                </button>
            )}

            <button
                className="btn position-absolute cursor-pointer text-white"
                onClick={closeModal}
                style={{
                    top: 20,
                    right: 20,
                    fontSize: "2rem",
                }}
            >
                ✖
            </button>
        </div>
      )}
    </div>
  );
};

export default CustomFancybox;
