import {Button, Card, Col, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {ICampaignBudget} from "../../../model/campaign/ICampaignsBudgetRanges";
import {CampaignsService} from "../CampaignsService";
import {ErrorToast, SuccessToast, WarningToast} from "../../../utils/toasters";
import {toast} from "react-toastify";
import {ICampaignsTypes} from "../../../model/campaign/ICampaignType";


export function CampaignBudgetRangeForm() {
    const [budget, setBudget] = useState<ICampaignBudget>({
        nbPlatforms: 0,
        minPrice: 0,
        maxPrice: 0,
        recommendedMaxBudget: 0,
        recommendedMinBudget: 0,
        creatorType: undefined
    })
    const [type, setType] = useState<ICampaignsTypes[]>([]);
    const {state: {id, isEdit}}: any = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (isEdit) {
            CampaignsService.getBudgetRange(id)
                .then((data) => {
                    setBudget({...budget, ...data})
                })
                .catch((err) => {
                    toast.error(`${err?.response?.data?.message}`)
                });
        }
        CampaignsService.getCampaignsTypes({isActive: true})
            .then((data) => {
                setType(data.data)
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
    }, []);

    const updateBudgetRange = () => {
        if (+budget.nbPlatforms < 1 || (budget.maxPrice === 0 || budget.minPrice === 0 || budget.recommendedMaxBudget === 0 || budget.recommendedMinBudget === 0)) return WarningToast('Select all values')
        if (budget.recommendedMinBudget < budget.minPrice) return WarningToast('Recommended minimal budget need to be higher then minimal price');
        if (budget.recommendedMaxBudget > budget.maxPrice) return WarningToast('Recommended maximal budget need to be lower then maximal price');
        if (budget.minPrice > budget.maxPrice) return WarningToast('Maximal price need to be higher then minimal price');
        if (budget.recommendedMinBudget > budget.recommendedMaxBudget) return WarningToast('Recommended maximal budget need to be higher then recommended minimal budget');
        return CampaignsService.updateBudgetRange(id, budget)
            .then((data) => {
                SuccessToast('Successfully created budget range')
                history.push("/campaign/budget-range")
            }).catch(error => ErrorToast(error))
    }


    const createBudgetRange = () => {
        if (+budget.nbPlatforms < 1 || (budget.maxPrice === 0 || budget.minPrice === 0 || budget.recommendedMaxBudget === 0 || budget.recommendedMinBudget === 0)) return WarningToast('Select all values')
        if (budget.recommendedMinBudget < budget.minPrice) return WarningToast('Recommended minimal budget need to be higher then minimal price');
        if (budget.recommendedMaxBudget > budget.maxPrice) return WarningToast('Recommended maximal budget need to be lower then maximal price');
        if (budget.minPrice > budget.maxPrice) return WarningToast('Maximal price need to be higher then minimal price');
        if (budget.recommendedMinBudget > budget.recommendedMaxBudget) return WarningToast('Recommended maximal budget need to be higher then recommended minimal budget');
        return CampaignsService.createBudgetRange(budget)
            .then((data) => {
                if (data === '') {
                    SuccessToast('Successfully created budget range')
                    history.push("/campaign/budget-range")
                }
            }).catch(error => ErrorToast(error))
    }


    const handleChange = (event: any) => {
        const value = event.target.value;
        setBudget({
            ...budget,
            [event.target.name]: Number(value) ? Number(value) : value
        });
    }

    const handleCreator = (event: any) => {
        const value = event.target.value;
        setBudget({
            ...budget,
            creatorType: type.find(t => t.id === +value)
        });
    }

    return (
        <Card>
            <Form as={Card.Body}>
                <h3> {isEdit ? 'Update budget range' : 'Create budget range'}</h3>
                <hr/>
                <Form.Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Number of platforms<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="number" name="nbPlatforms" onChange={handleChange}
                                            value={budget.nbPlatforms}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Min price<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="number" name="minPrice"
                                            onChange={handleChange}
                                            value={budget.minPrice}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Max price<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="number" name="maxPrice"
                                            onChange={handleChange}
                                            value={budget.maxPrice}/>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Creator type<span className="text-danger">*</span></Form.Label>
                            <Form.Control as="select" name="creatorType"
                                            value={budget.creatorType?.id}
                                            onChange={handleCreator}>
                                <option hidden>Select one type</option>
                                {type.map((item) => <option
                                    key={item.id}
                                    value={item.id}>{item.title}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Recommended min budget<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="number" name="recommendedMinBudget"
                                            onChange={handleChange}
                                            value={budget.recommendedMinBudget}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Recommended max budget<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="number" name="recommendedMaxBudget"
                                            onChange={handleChange}
                                            value={budget.recommendedMaxBudget}/>
                        </Form.Group>
                    </Col>

                </Form.Row>
                <Button
                    onClick={isEdit ? updateBudgetRange : createBudgetRange}
                    className="col-md-1 col-sm-12 "
                    size='lg' variant="primary"
                    type="submit">
                    Save
                </Button>
            </Form>
        </Card>
    )
}