import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Table} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {Campaign} from '../../../model/campaign/Campaign';
import {Pagination} from '../../../shared/components/Pagination';
import {CampaignsService} from '../CampaignsService';
import {toast} from 'react-toastify';
import {formatDate} from "../../../utils/Functions";

export function CampaignEmailing() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        title: '',
        status: 'published',
        approvalStatus: 'approved'
    })

    const [campaign, setCampaign] = useState<Campaign[]>([])
    const [change, setChange] = useState(false)
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        CampaignsService.getCampaigns(state)
            .then((data) => {
                setCampaign(data.data);
                setTotalPages(Math.ceil(data.count / state.perPage));
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
        return () => {
        };
    }, [change]);

    function handleClick() {
        setChange(!change);
        setState({
            ...state,
            page: 1,
        });
    }

    function handleChange(evt: any) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage});
        setChange(!change);
    };

    return (
        <>
            <div className="card mb-5 p-4 mt-3"
                 onKeyDown={(e) => {
                if (e.key === "Enter") handleClick();
            }}>
                <Form.Row>
                    <Form.Group as={Col} className='col-4 mb-0'>
                        <Form.Control placeholder='Title or Campaign ID' className='autocomplete-input' name="title" onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group as={Col} className='mb-0'>
                        <Button  type="submit" onClick={() => handleClick()}>
                            Search
                        </Button>
                    </Form.Group>
                </Form.Row>
            </div>

            <div className="card mb-5 p-4 table-responsive">
                <Table hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Apply until</th>
                            <th>Email sent</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {campaign?.map((e) => (
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.title}</td>
                            <td>{formatDate(e.date)}</td>
                            <td><span className={`status ${e.isInfluencersNotificationSent ? 'accepted' : 'declined'}`}>
                                {e.isInfluencersNotificationSent ? 'Email sent' : 'Not sent'}</span>
                            </td>
                            <td>
                                <span className={`status ${e?.status}`}>{e?.status === 'published' ? 'public' : e.status}</span>
                            </td>
                            <td>
                                <Button variant="primary"
                                        onClick={() => history.push(`/campaign/emailing/preview`, {
                                            id: e.id,
                                            sendEmail: e.isInfluencersNotificationSent,
                                            sentLanguages: e.sentLanguages
                                        })}>
                                    Send email
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                {totalPages <= 1 ? '' :
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>

            </div>

        </>
    )
}
