import React from 'react'
import {Dropdown, DropdownButton, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {getCorrectEnums, getCorrectSocialUrl} from '../../../../utils/Functions';
import { ImpersonateEye } from '../../../../shared/components/ImpersonateEye';
import {IUser} from "../../../../model/user/IUser";
import Flag from "react-world-flags";
import {getCountryName} from "../../../../helpers/CorrectCountryNames";

interface IInfluencerGeneralTable {
    user: IUser[];
    suspendUser: (...args: any) => void;
    passData: (...args: any) => void;
    setShowChangePassword: (...args: any) => void;
    setSelectedUser: (...args: any) => void;
    disableUsers: (...args: any) => void;
}

export default function InfluencerGeneralTable({
        user, 
        suspendUser, 
        passData, 
        setShowChangePassword, 
        setSelectedUser, 
        disableUsers} : IInfluencerGeneralTable) {
  return (
    <Table responsive className="table scroll-10 ">
        <thead>
            <tr className="even">
                <th>ID</th>
                <th>Displayname<br/>first/last </th>
                <th>Gender</th>
                <th>Country<br/>Residence</th>
                <th>Handles</th>
                <th>Email</th>
                <th>Agency ID</th>
                <th>All Offers</th>
                <th>Campaigns</th>
                <th>Categories</th>
                <th>Price</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {user?.map((e: IUser) => (
                <tr key={e.id}>
                    <td>{e.id}</td>
                    <td>
                        {e.isEnabled === false ? 'DISABLED': e.displayName}
                        <br/>
                        {e.isEnabled === false ? 'USER': e.firstName+'-'+e.lastName}
                    </td>
                    <td>{e.gender ? getCorrectEnums(e.gender) : '-'}</td>
                    <td>
                        <Flag className='country-flag cursor-pointer' code={e?.contact?.country} title={getCountryName(e.contact.country)}/>
                        <br/>{e?.cityOfResidence ?? 'n/a'}
                    </td>
                    <td>
                        {e?.handles?.map((item :any) => (
                            <a target="_blank"
                            key={item.id}
                            className="d-flex align-items-center mb-2 mr-3"
                            href={`${getCorrectSocialUrl(item?.mainPlatform?.platformEnum as string, item?.title, item?.externalLink)}`}
                            rel="noreferrer">
                                <img src={item?.mainPlatform?.path} alt="Platform icon"/>
                                <span className="ml-1 mb-0">
                                    @{item?.title}
                                </span>
                            </a>
                        ))}
                    </td>
                    <td><ImpersonateEye id={e.id ?? 0}/>{e.email}</td>
                    <td>{e?.agencyCompany?.id ?? 'n/a'}</td>
                    <td>{e?.allOffers}</td>
                    <td>{e?.completedCampaigns}</td>
                    <td>
                        {e?.categoriesArray?.map((item: any, i) => (
                        <OverlayTrigger key={`${item.name}`+i} placement="top"
                                        overlay={<Tooltip
                                            id="campaign-tooltip">
                                            {getCorrectEnums(item?.name)}
                                        </Tooltip>}>
                            <img width={25} src={`/icons/categories/${item?.name}.svg`} alt={item?.name}
                                 className='cursor-pointer'/>
                        </OverlayTrigger>))}
                    </td>
                    <td>{e?.offersPrice}</td>
                    <td>
                        <DropdownButton title="Actions" id="dropdown-menu-align-right">
                            <Dropdown.Item eventKey="1"
                                           onClick={() => passData(`/user/edit/${e.id}/true`)}>
                                Edit
                            </Dropdown.Item>
                            {(e.userType === 'influencer') && (
                                <>
                                <Dropdown.Item eventKey="2"
                                    onClick={() => passData('/user/devices', {user: e})}
                                >
                                    Devices
                                </Dropdown.Item>

                                <Dropdown.Item eventKey="2"
                                    onClick={() => passData('/user/platforms', {
                                        id: e.id,
                                        user: e
                                    })}
                                >
                                    Edit platforms
                                </Dropdown.Item> 
                                </>
                            )}
                            <Dropdown.Item eventKey="3" onClick={() => {
                                setShowChangePassword(true)
                                setSelectedUser(e);
                            }}>
                                Change password
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={() => disableUsers(e.id ?? 0)}
                                        eventKey="3">{e.isEnabled ? 'Disable' : 'Enable'}</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={() => suspendUser(e.id ?? 0)}
                                        eventKey="4">{e.isSuspended ? 'Lift suspension' : 'Suspend'}</Dropdown.Item>
                        </DropdownButton>
                    </td>
                </tr>
            ))}
        </tbody>
        {user.length < 15 && !!user.length && (
            <div className='h-180px'></div>
        )}
    </Table>
  )
}
