import * as React from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {useEffect, useState} from 'react';
import {IModalDeleteData, IUser} from '../../../model/user/IUser';
import {UserService} from '../UserService';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {UPDATED_USER} from '../../../enum/Messages';
import {Contact} from '../../../model/shared/Contact';
import {IAgency} from '../../agency/dto/iAgency';
import {CompanyService} from '../../company/CompanyService';
import {Company} from '../../../model/company/Company';
import Autocomplete from 'react-autocomplete';
import {getCorrectEnums} from '../../../utils/Functions';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {useParams} from 'react-router-dom';
import InfluencerRemoveModal from "../../agency/components/InfluencerRemoveModal";

export function UserForm() {
    const {id, isUserList}: any = useParams();
    const {authToken} = useSelector((reduxState: any) => reduxState.auth);

    const initialContact: Contact = {
        city: '',
        country: '',
        phone: '',
        province: '',
        street: '',
        uidNumber: '',
        zip: ''
    }

    const initialAgency: IAgency = {
        displayName: '',
        firstName: '',
        id: 0,
        lastName: ''
    }

    const history = useHistory();

    const [user, setUser] = useState<Partial<IUser>>({
        agency: initialAgency,
        agencyId: 0,
        ambassadorStatus: '',
        averageLikeToFollowerRatio: 0,
        birthdate: '',
        brandType: '',
        contact: initialContact,
        displayName: '',
        email: '',
        firstName: '',
        gender: '',
        id: 0,
        isEnabled: false,
        isManaged: false,
        lastLogin: '',
        lastLoginIp: '',
        lastName: '',
        referralCode: '',
        roles: [],
        totalEngagements: 0,
        totalFollowers: 0,
        totalReach: 0,
        userType: '',
        username: '',
        brandCompany: {
            id: 0
        }
    })
    const [selectedCompany, setSelectedCompany] = useState('');
    const [companyList, setCompanyList] = useState<Company[]>([]);
    const [filterState, setFilterState] = useState({
        page: 1,
        perPage: 10000,
        title: ''
    });
    const [agencies, setAgencies] = useState<IUser[]>([]);
    const [agenciesParams, setAgenciesParams] = useState('');
    const {userType, roles} = useSelector((state: any) => state.enums)
    const isUserBrand = user?.userType === 'brand_master' || user?.userType === 'brand';
    const [modalDeleteData, setModalDeleteData] = useState<IModalDeleteData>({
        showModal: false,
        userId: 0,
        userName: "",
    });
    const [change, setChange] = useState(false);


    useEffect(() => {
        UserService.getUser(id).then((data) => {
            setUser(data);
            setAgenciesParams(data?.agencyCompany?.displayName!);
            setSelectedCompany(data?.brandCompany?.displayName ?? '')
        }).catch((err) => {
            toast.error(`${err.message}`);
        });
        return () => {};
    }, [change])

    useEffect(() => {
        CompanyService.getCompanies(filterState).then(response => {
            setCompanyList(response?.data)
        }).catch((err) => {
            toast.error(`${err.message}`);
        });
    }, [filterState])

    useEffect(() => {
        UserService.getAgencies({displayName: agenciesParams, perPage: 10000}).then((data) => {
            setAgencies(data.data);
        }).catch((err) => {
            toast.error(`${err.message}`);
        });
    }, [agenciesParams])

    function editUser() {
        UserService.editUser(id, user).then((data) => {
            setUser(data)
            toast(UPDATED_USER)
            history.goBack();
        }).catch(err => {
            toast.error(`${err.response.data.message}`)
        })
    }

    function removeUserFromAgency() {
        UserService.editUser(id, user).then((data) => {
            setUser(data)
            toast(UPDATED_USER)
            history.goBack();
        }).catch(err => {
            toast.error(`${err.response.data.message}`)
        })
    }

    function handleChange(evt: any, isNumber: boolean = false) {
        const value = isNumber ? +evt.target.value : evt.target.value;
        setUser({
            ...user,
            [evt.target.name]: value
        });
    }

    function handleCheckbox(evt: any) {
        const change = evt.target.checked;
        setUser({
            ...user,
            [evt.target.name]: change
        });
    }

    function handleRole(e: any) {
        let options = e.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setUser({
            ...user,
            roles: value
        })
    }

    const changeFilterHandler = (event: any) => {
        const {value} = event.target;
        setSelectedCompany(value);
        setFilterState(prev => ({...prev, displayName: value}))
    };

    const changeAutoCompleteHandler = (ev: any, valueToUpdate: Company, field: string) => {
        if (field === 'brandCompany') {
            setSelectedCompany(valueToUpdate?.displayName);
        } else {            
            setAgenciesParams(valueToUpdate?.displayName);
            // setUser({...user, agency: {id: valueToUpdate?.id}, agencyCompany: {displayName: valueToUpdate?.displayName}})
        }

        setUser(prev => ({...prev, [field]: field === 'agency' ? valueToUpdate.id : valueToUpdate}))
    }
    
    return (
        <>
            <InfluencerRemoveModal {...modalDeleteData} setModalDeleteData={setModalDeleteData} setChange={setChange}/>

            <Row className='align-items-center mb-3'>
                <Col className='col-auto'>
                    <h3 className='m-0'>{user?.displayName ? `ID: ${user.id} Name: ${user.displayName}` : `ID: ${user.id} Name: ${user.firstName} ${user.lastName}`}</h3>
                </Col>
                <Col className='col-auto'>
                    <ImpersonateEye id={id} style='btn-primary btn-icon'/>
                </Col>
            </Row>
            <Card className="mb-5">
                <Form as={Card.Body}>
                    {(Boolean(isUserList) || ['brand', 'partner'].indexOf(user?.userType!) > -1) &&
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridState" className='autocomplete-input zindex-5 position-relative d-flex flex-column'>
                                <Form.Label>Agency</Form.Label>
                                <Autocomplete
                                    items={agencies}
                                    getItemValue={(item) => item?.displayName ?? '-'}
                                    value={agenciesParams ?? ''}
                                    renderItem={(item) =>
                                        <div className='autocomplete-item' key={item.id}>
                                            {item.displayName ?? '-'}
                                        </div>
                                    }
                                    onChange={(e) => setAgenciesParams(e.target.value)}
                                    onSelect={(_, agency) => changeAutoCompleteHandler(_,agency, 'agency')}
                                />
                            </Form.Group>
                        </Form.Row>
                    }
                    {agenciesParams ? <Button onClick={() =>
                        setModalDeleteData({
                        ...modalDeleteData,
                        showModal: true,
                        userId: Number(user.id),
                        userName: user?.displayName
                    })
                    } variant="danger">
                        Remove from agency
                    </Button> : null }
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" onChange={handleChange} defaultValue={user?.email} type="email"/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control name="username" onChange={handleChange} defaultValue={user?.username}
                                          type="userName"/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridDisplayName">
                            <Form.Label>Display name</Form.Label>
                            <Form.Control name="displayName" onChange={handleChange} defaultValue={user?.displayName}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formFirstName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control name="firstName" onChange={handleChange} defaultValue={user?.firstName}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formLastName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control name="lastName" onChange={handleChange} defaultValue={user?.lastName}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='position-relative zindex-1'>
                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Role</Form.Label>
                            <Form.Control name="roles" onChange={handleRole} value={user.roles} as="select" multiple>
                                {roles.map((role: string) =>
                                    <option key={role}>{role}</option>)}
                            </Form.Control>
                        </Form.Group>
                        {isUserBrand && 
                            <Form.Group as={Col} controlId="formGridCompany">
                                <div className="autocomplete-input position-relative d-flex flex-column">
                                    <Form.Label>Company</Form.Label>
                                    <Autocomplete
                                        getItemValue={(item) => item?.displayName ?? '-'}
                                        items={companyList}
                                        renderItem={(item) =>
                                            <div className='autocomplete-item'>
                                                {item.displayName ?? '-'}
                                            </div>
                                        }
                                        value={selectedCompany ?? ''}
                                        onChange={(e) => changeFilterHandler(e)}
                                        onSelect={(ev, company) => changeAutoCompleteHandler(ev, company, 'brandCompany')}
                                    />
                                </div>
                            </Form.Group>
                        }
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Type</Form.Label>
                            <Form.Control name="userType" onChange={handleChange} as="select" value={user?.userType!}>
                                {userType.map((type: string) => <option key={getCorrectEnums(type)} value={type}>
                                    {getCorrectEnums(type)}
                                    </option>)}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} id="formGridCheckbox">
                            <Form.Check onChange={handleCheckbox} name="isManaged" type="checkbox" label="Managed"
                                        checked={user?.isManaged}/>
                        </Form.Group>

                        <Form.Group as={Col} id="formGridCheckbox1">
                            <Form.Check onChange={handleCheckbox} name="isSupervisor" type="checkbox" label="Supervisor"
                                        checked={user?.isSupervisor}/>
                        </Form.Group>
                    </Form.Row>

                    <Button onClick={() => editUser()} variant="primary">
                        Save
                    </Button>
                </Form>
            </Card>
        </>
    )
}
