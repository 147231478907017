import React from 'react'
import { Card } from 'react-bootstrap'

interface ICardLayout {
    children: React.ReactNode;
    classCard?: string;
    classBody?: string;
}

export default function CardLayout({children, classCard, classBody}: ICardLayout) {
  return (
    <Card className={classCard}>
        <Card.Body className={classBody}>
            {children}
        </Card.Body>
    </Card>
  )
}
