import {BaseModel} from '../../model/shared/BaseModel';
import {requests} from '../../services';
import {LIST_AMBASSADORS_INV_URL, LIST_AMBASSADORS_URL, LIST_REFERRED_CAMP_URL} from '../../utils/Endpoints';
import {IUser} from '../../model/user/IUser';
import {Invoices} from '../../model/invoice/Invoices';
import {IReferredCampaign} from '../../model/campaign/IReferredCampaign';
import {CampaignRequest} from '../../model/campaign/CampaignRequest';

export const AmbassadorService = {
    getAmbassador: (params: any): Promise<BaseModel<IUser>> => requests.get(LIST_AMBASSADORS_URL, params),
    updateAmbassador: (id: number, status:string, body?: IUser): Promise<IUser> => requests.put(`${LIST_AMBASSADORS_URL}/${id}?ambassadorStatus=${status}`, {}),
    getInvoices: (params: any): Promise<BaseModel<Invoices>> => requests.get(LIST_AMBASSADORS_INV_URL, params),
    updateInvoices: (id: number, status:string, body?: IUser): Promise<IUser> => requests.put(`${LIST_AMBASSADORS_INV_URL}/${id}?status=${status}`, {}),
    getRefCampaigns: (params: any): Promise<BaseModel<IReferredCampaign>> => requests.get(LIST_REFERRED_CAMP_URL, params),
    addRefCampaign:(camp: CampaignRequest): Promise<IReferredCampaign> => requests.post(LIST_REFERRED_CAMP_URL, camp)
}
