import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {getCorrectEnums} from '../../../utils/Functions';
import {AdminAclRequest, AdminService} from '../AdminService';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';

export interface IAdminAccessRightsModalProps {
    show: boolean,
    closeModal: () => void
    id: number
    aclArr: string[];
}
export function AdminAccessRightsModal({show, closeModal, id, aclArr}: IAdminAccessRightsModalProps) {
    const {AdminAclEnum} = useSelector((state: any) => state.enums)
    const {user} = useSelector((state: any) => state.auth)
    const [accessRight, setAccessRight] = useState<AdminAclRequest>({
        adminAcl: aclArr
    });

    const handleChange = (e:any) => {
        const {name, value} = e.target;
        setAccessRight((prev) => {
            if (name === 'adminAcl') {
                return {
                    ...prev,
                    [name]: !!e.target.checked ? [...accessRight.adminAcl!, value ] : accessRight?.adminAcl?.filter(item => item !== value)
                }
            }
            return {
                ...prev,
                [name]: value === 'undefined' ? undefined : value
            }
        })
    }

    const saveAccessRight = () => {
        AdminService.editAdminAcl(id, accessRight)
            .then((response) => {
                SuccessToast('Successfully updated access right')
                if(user.id === response.id) {
                    localStorage.setItem("adminAcl", String(response?.adminAcl));
                    window.location.reload();
                }
                closeModal()
            }).catch(err => ErrorToast(err))
    }

    return (
        <Modal
            show={show}
            onHide={closeModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update Access Right
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='row'>
                    {!!AdminAclEnum && !!AdminAclEnum.length && AdminAclEnum?.map((item: string, index: number) => {
                        return (
                            <Form.Group key={index} className='col-6'>
                                <Form.Check label={getCorrectEnums(item)} type='checkbox' value={item} name='adminAcl'
                                            onChange={(e:any) => handleChange(e)}
                                            checked={accessRight?.adminAcl?.includes(item)}
                                />
                            </Form.Group>
                        )
                    })}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal}>Close</Button>
                <Button variant="primary" onClick={saveAccessRight}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}