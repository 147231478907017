import React, {useEffect, useState} from 'react';
import {AgencyService} from '../AgencyService';
import {useHistory} from 'react-router';
import {IUser} from '../../../model/user/IUser';
import {Card, Dropdown, DropdownButton, Table} from 'react-bootstrap';
import {FaCheck, FaTimes, FaUser} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import InfluencerRemoveModal from "./InfluencerRemoveModal";
import {formatDate, getCorrectEnums} from "../../../utils/Functions";
import {CustomImageHandler} from "../../../helpers/CustomImageHandler";

export function AgencyManagers () {
    let history = useHistory();
    const [users, setUsers] = useState<IUser[]>([]);
    const {id}: any = useParams();
    const [modalDeleteData, setModalDeleteData] = useState({
        showModal: false,
        userId: 0,
        userName: "",
    });
    const [change, setChange] = useState(false);

    useEffect(()=>{
        AgencyService.getManagers(id).then(data=>{
            setUsers(data)
        }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    function disableUsers (id: number){
        AgencyService.disableUser(id)
            .then((data) => {
                setUsers(prevUsers => {
                    const updatedUsers = prevUsers.map(user =>
                        user.id === id ? data : user
                    );
                    return updatedUsers;
                });
            })
            .catch((err) => {});
    }

    function passData(route: string, data?: any) {
        history.push({
            pathname: route,
            state: data
        })
    }

    return (
        <>
            <InfluencerRemoveModal {...modalDeleteData} setModalDeleteData={setModalDeleteData} setChange={setChange}/>

            <Card className="card-custom mb-5 p-4">
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Photo</th>
                            <th>Displayname</th>
                            <th>Type</th>
                            <th>Position</th>
                            <th>Enabled</th>
                            <th>Email</th>
                            <th>Last login</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {users.map((e)=>(
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>
                                <CustomImageHandler
                                    altTag={''}
                                    classes="rounded-circle"
                                    thumbnailPath={e.profilePhoto?.thumbnail ?? ''}
                                    photoPath={e.profilePhoto?.thumbnail ?? ''}
                                    style={{width: 50, height: 50}}
                                />
                            </td>
                            <td>{e.displayName}<br/>{e.firstName} - {e.lastName}</td>
                            <td>{getCorrectEnums(String(e.userType))}</td>
                            <td>{e.position ? getCorrectEnums(e.position): '-'}</td>
                            <td>{e.isEnabled ? <span className="word-break text-primary"><FaCheck/></span> :
                                <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td><ImpersonateEye id={e.id ?? 0}/>{e.email}</td>
                            <td>{formatDate(e.lastLogin)}<br/> IP:{e.lastLoginIp}</td>
                            <td>
                                <DropdownButton alignRight title={<FaUser/>} id="dropdown-menu-align-right">
                                    <Dropdown.Item eventKey="1" onClick={() => passData(`/user/edit/${e.id}/true`)}>
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => disableUsers(e.id ?? 0)} eventKey="2">
                                        {e.isEnabled ? 'Disable' : 'Enable'}
                                    </Dropdown.Item>
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <Dropdown.Item className='bg-danger-o-50' onClick={() =>
                                        setModalDeleteData({
                                            ...modalDeleteData,
                                            showModal: true,
                                            userId: Number(e.id),
                                            userName: e.displayName
                                        })
                                    }>
                                        Remove
                                    </Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    {users.length < 15 && !!users.length && (
                        <div className='h-180px'></div>
                    )}
                </Table>
            </Card>
        </>
    )
}
