import React, {SetStateAction} from 'react';
import {Card, Form, Row} from 'react-bootstrap';
import {Company} from '../../../model/company/Company';
import AutocompleteSelect from '../../../shared/components/AutocompleteSelect';
import _ from 'lodash';

interface IProps {
    company: Company[];
    setPagination: React.Dispatch<SetStateAction<any>>
}

const OnboardingFilters = ({company, setPagination}: IProps) => {
    const handleChange = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setPagination((prev: any) => ({...prev, [name]: value}))
    }, 300)

    return (
        <Card className="mb-5">
            <Card.Body>
                <Row>
                    <div className="form-group col-md col-sm-12">
                        <AutocompleteSelect className="autocomplete-input" list={company} listKey={'displayName'}
                                            updateState={(id) => setPagination((prev: any) => ({...prev, companyId: id}))}
                                            placeholder={'Company'}/>
                    </div>
                    <div className="form-group col-md col-sm-12">
                        <Form.Control name="handle" type="text" placeholder="Handle...." onChange={handleChange}/>
                    </div>
                    <div className="col-md-2 col-6">
                        <Form.Control placeholder="Type" className="autocomplete-input" name="isContacted" onChange={handleChange} as="select">
                            <option value="null">is Contacted?</option>
                            <option value="true">Contacted</option>
                            <option value="false">NotContacted</option>
                        </Form.Control>
                    </div>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default OnboardingFilters;
