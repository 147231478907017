import React, {useEffect, useState} from 'react';
import {ReportingService} from './ReportingService';
import {Report} from '../../model/report/Report';
import {toast} from 'react-toastify';
import {Button, Card, Table} from 'react-bootstrap';
import {Pagination} from '../../shared/components/Pagination';
import {SUCCESSFULLY} from '../../enum/Messages';
import {ImpersonateEye} from '../../shared/components/ImpersonateEye';
import {formatDate, getCorrectEnums, getPlatformValue} from "../../utils/Functions";
import ConfirmModal from './components/ConfirmModal';
import ReportingFilters from './components/ReportingFilters';

export function Reporting() {
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        campaignId: undefined
    })
    const [showModal, setShowModal] = useState({
        showModal: false,
        itemData: {}
    });
    const [totalPages, setTotalPages] = useState(0);
    const [reports, setReports] = useState<Report[]>([])
    const [reportingTrigger, setReportingTrigger] = useState(false);

    useEffect(() => {
        ReportingService.getReports({...state, page: !!state.campaignId ? 1 : state.page})
            .then((data) => {
                setReports(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.message}`)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, reportingTrigger])


    function remindReport(id: number) {
        ReportingService.reportsRemind(id)
            .then((data) => {
                toast(SUCCESSFULLY);
                setShowModal((prev) => ({...prev, showModal: false}))
            }).catch(err => toast.error(`${err.message}`))
    }

    const handlePages = (updatePage: number) => {
        setState({
            ...state,
            page: updatePage
        })
    };

    return (
        <>
            <ConfirmModal setShowModal={setShowModal} {...showModal} func={remindReport}/>
            <ReportingFilters setPagination={setState} setReportingTrigger={setReportingTrigger}/>
            
            <Card className="mb-5">
              <Card.Body>
                <Table hover responsive bordered>
                    <thead>
                        <tr>
                            <th>Campaign</th>
                            <th>Date</th>
                            <th>Influencer</th>
                            <th>Offer</th>
                            <th>Reported</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {reports?.map((report) => (
                        <tr key={report?.id + '-' + report?.title}>
                            <td>{report?.title}</td>
                            <td align="center">
                                {formatDate(report?.publishFrom)} - {formatDate(report?.publishTo)}
                            </td>
                            <td>{report?.offers.map((offer) => 
                                <p
                                    key={offer?.user?.id + '-' + offer?.user?.updatedAt}>
                                        <ImpersonateEye id={offer?.user?.id ?? 0}/>
                                    {offer?.user?.displayName}
                                </p>
                                )}
                            </td>
                            <td>{report?.offers.map((offer) => offer?.offerItems.map((item) => <p
                                key={item?.id}>{getPlatformValue(item?.platform)} - {item?.nbPosts}</p>))}
                            </td>
                            <td>{report?.offers.map((offer) => offer?.offerItems.map((item) => <p
                                key={item?.id}>{item?.reportedCount} / {item?.nbPosts}</p>))}
                            </td>
                            <td>
                                <Button onClick={() => setShowModal((prev) => ({...prev, showModal: true, itemData:{id: report.id}}))}>
                                    Remind
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages > 1 &&
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
              </Card.Body>
            </Card>
        </>
    )
}
