import * as React from 'react';
import {Button, Card, Col, Form} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {IUser} from '../../../model/user/IUser';
import {toast} from 'react-toastify';
import {AgencyService} from '../AgencyService';
import {useHistory, useLocation} from 'react-router';
import {useSelector} from 'react-redux';
import {LocalRoutes} from '../../../enum/LocalRoutes';
import {ADDED_USER, UPDATED_USER} from '../../../enum/Messages';
import {ErrorToast} from '../../../utils/toasters';
import CardLayout from '../../../shared/components/CardLayout';

export function FormAgency() {
    const history = useHistory()
    const {country} = useSelector((state: any) => state.enums);
    const {state: {id}, state: {isEdit}}: any = useLocation();
    
    const [user, setUser] = useState<Partial<IUser>>({});
    const [validated, setValidated] = useState(false);


    useEffect(() => {
        if (isEdit) {
            AgencyService.getAgency(id).then((data) => {
                setUser(data);
            }).catch((err) => ErrorToast(err));
        }
    },[])

    function editUser(event: any) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);
        AgencyService.editAgency(id, user).then((data)=>{
            setUser(data)
            toast(UPDATED_USER)
        }).catch(err=>{
            toast.error(`${err.response.data.message}`);
        })
    }

    function createUser(event: any) {
        event.preventDefault();
        const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //   event.stopPropagation();
        // }
        setValidated(true);
        AgencyService.createAgency(user).then((data)=>{
            if (data.id) {
                history.push(LocalRoutes.AGENCY_LIST)
            }
            toast(ADDED_USER)
        }).catch((err) => {
            toast.error(`${err.response.data.message}`);
        })
    }

    function handleChange(evt: any, isContact: boolean = false) {
        const { value, name } = evt.target;
    
        setUser((prevUser) => {
            if (isContact) {
                return {
                    ...prevUser,
                    contact: {
                        ...prevUser.contact,
                        [name]: value,
                    } as IUser["contact"],
                };
            } else {
                return {
                    ...prevUser,
                    [name]: value,
                };
            }
        });
    }

    return (
        <Form onSubmit={isEdit ? editUser : createUser} noValidate validated={validated}>
            <CardLayout classCard="mb-5">
                <h3>Company info</h3>
                <hr/>
                <Form.Row>
                    <Form.Group as={Col} className='mb-0'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control required name="email" onChange={handleChange} defaultValue={user?.email}/>
                    </Form.Group>

                    <Form.Group as={Col} className='mb-0'>
                        <Form.Label>Display name</Form.Label>
                        <Form.Control required name="displayName" onChange={handleChange} defaultValue={user?.displayName} />
                    </Form.Group>
                </Form.Row>
            </CardLayout>

            {/*CONTACT*/}

            <CardLayout classCard="mb-5">
                <h3>Contact</h3>
                <hr/>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control name="phone" onChange={e => (handleChange(e, true))} defaultValue={user?.contact?.phone ?? ""} />
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>Street, House Number, Appartment Number</Form.Label>
                        <Form.Control name="street" onChange={e => (handleChange(e, true))} defaultValue={user?.contact?.street}  />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control name="zip" onChange={e => (handleChange(e, true))} defaultValue={user?.contact?.zip} />
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control name="city" onChange={e => (handleChange(e, true))} defaultValue={user?.contact?.city} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control name="province" onChange={e => (handleChange(e, true))} defaultValue={user?.contact?.province} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>Country</Form.Label>
                        <Form.Control name="country" onChange={e => (handleChange(e, true))} as="select" value={user?.contact?.country}>
                            {country?.map((country: string) => <option key={country}>{country}</option>)}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group className='col-md-6 mb-0'>
                        <Form.Label>VAT number</Form.Label>
                        <Form.Control name="uidNumber" onChange={e => (handleChange(e, true))} defaultValue={user?.contact?.uidNumber} />
                    </Form.Group>
                </Form.Row>
            </CardLayout>
            <Button size='lg' type='submit'>
                Save
            </Button>
        </Form>
    )
}
