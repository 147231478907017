import React, {useEffect, useState} from 'react';
import {Card, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {Pagination} from '../../../shared/components/Pagination';
import {BudgetOverviewService} from './BudgetOverviewService';
import {IBudget} from '../../statistics/dto/iBudget';
import {formatDate, getCorrectEnums} from "../../../utils/Functions";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {priceSplitter} from "../../../helpers/NumberFormater";
import {BudgetOverviewFilter} from "./BudgetOverviewFilter";
import {calculateFee} from "../../../helpers/FeeCaluculation";

export function BudgetOverview() {
    const [budget, setBudget] = useState<IBudget[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [campaignsFilter, setCampaignsFilter] = useState({
        page:1,
        perPage: 15,
        id: "",
        from: undefined,
        to: undefined,
    })

    function getCampaigns(campaignsFilter: any, updatePage?: number) {
        BudgetOverviewService.getBudgetOverview({...campaignsFilter, page: !!updatePage ? updatePage: campaignsFilter.page})
            .then((data) => {
                setBudget(data?.data);
                setCampaignsFilter({...campaignsFilter, page: data.page})
                setTotalPages(Math.ceil(data.count / campaignsFilter.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
    }

    useEffect(() => {
        getCampaigns(campaignsFilter);
    }, [campaignsFilter?.id]);

    const handlePages = (updatePage: number) => {
        getCampaigns(campaignsFilter, updatePage)
    };

    return(
        <>
            <Card className="mb-5 p-4"
                  onKeyDown={(e: any) => {
                      if (e.key === "Enter") getCampaigns(campaignsFilter);
                  }}
            >
                <BudgetOverviewFilter handleState={getCampaigns} setState={setCampaignsFilter} budgetFilter={campaignsFilter}/>
            </Card>

            <Card className="card-custom mb-5 p-4 table-responsive">
                <Table hover>
                    <thead>
                        <tr>
                            <th>Date of creation</th>
                            <th>Id</th>
                            <th>Campaign</th>
                            <th>Fee</th>
                            <th>Package</th>
                            <th>Company</th>
                            <th>Application deadline</th>
                            <th>Email</th>
                            <th>Planned budget</th>
                            <th>Total spent</th>
                        </tr>
                    </thead>
                    <tbody>
                    {budget?.map((e) => (
                        <tr key={e.id}>
                            <td>{formatDate(e?.createdAt)}</td>
                            <td>{e?.id}</td>
                            <td>{e?.title}</td>
                            <td>{e?.feePercentage ? calculateFee(e?.feePercentage) : ''}</td>
                            <td>{getCorrectEnums(e?.packageName ?? '')}</td>
                            <td>{getCorrectEnums(e?.companyName ?? '')}</td>
                            <td>{formatDate(e?.date)}</td>
                            <td><ImpersonateEye id={e?.userId}/>{e?.email}</td>
                            <td className='text-right'>{priceSplitter(e?.grossBudget ?? 0)} €</td>
                            <td className={`${e?.grossBudget > e?.totalSpent ? 'text-danger' : 'text-primary'} text-right`}>
                                {e?.isLongTerm ? priceSplitter(e?.totalSpentLong) : priceSplitter(e?.totalSpent)} €
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages > 1 &&
                        <Pagination
                            page={campaignsFilter.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </Card>
        </>
    )
}
