import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCorrectEnums } from "../../../utils/Functions";
import { IReportItem } from "../../../model/report/Report-item";
import generalSlice from "../../../store/general/GeneralSlice";

interface IModalReject {
    showModal: boolean,
    setModalData: (args: any) => void,
    updateReport: (args: any) => void;
}

export default function ModalReportConfirm({ showModal, setModalData, updateReport }: IModalReject) {
    return (
        <Modal
            show={showModal}
            onHide={() => setModalData((prev: any) => ({ ...prev, showModal: false }))}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Do you want to approve current reporting?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button className='m-0' variant='danger'
                        onClick={() => setModalData((prev: any) => ({...prev, showModal: false})) }>
                    No
                </Button>
                <Button className='m-0 ml-2' onClick={updateReport}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
}
