import {requests} from '../../services';
import {REPORTING_URL} from '../../utils/Endpoints';
import {IReportItem} from "../../model/report/Report-item";

export const ReportsService = {
    getReports: (params: any) => requests.get(`${REPORTING_URL}/ai/posts`, params),
    toggleVerified: (id: number) => requests.put(`${REPORTING_URL}/toggle/${id}`,{}),
    updateReport: (id: number, report:Partial<IReportItem>) => requests.put(`${REPORTING_URL}/item/update/${id}`,report),
    rejectReport: (id: number, report:Partial<IReportItem>) => requests.put(`${REPORTING_URL}/item/reject/${id}`,report),
    getReportItem: (id: number) => requests.get(`${REPORTING_URL}/item/${id}`,{}),
}
