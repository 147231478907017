import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Tab, Table} from 'react-bootstrap';
import {IStatisticData} from './dto/iStatistic';
import {StatisticsService} from './StatisticsService';
import {toast} from 'react-toastify';
import {calculatePercentage} from "../../helpers/CalculatePercentage";
import Flag from "react-world-flags";
import {getCountryName} from "../../helpers/CorrectCountryNames";

export function Statistics() {
    const [statistics, setStatistics] = useState<IStatisticData>(
        {totalInfluencer:'', totalBrand:'', statistic:[]})
    const [userType, setType] = useState('influencer')

    useEffect(()=>{
        StatisticsService.getStatistics(userType)
            .then((data) => {
                setStatistics(data)
            }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    },[userType])

    return(
        <>
            <div className="card card-custom mb-5 p-4 mt-3">
                <Tab.Container id="list-group-tabs" defaultActiveKey="#influencer" >
                    <Row>
                        <Col>
                            <Button block size="lg"  onClick={()=>setType("influencer")} variant={userType === "influencer" ? "primary" : "outline-primary"} >
                                Top countries for influencers ({statistics?.totalInfluencer})
                            </Button>
                        </Col>
                        <Col className="">
                            <Button block size="lg"  onClick={()=>setType("brand")} variant={userType === "brand" ? "primary" : "outline-primary"} >
                                Top countries for brands ({statistics?.totalBrand})
                            </Button>
                        </Col>
                    </Row>
                </Tab.Container>

                <Table hover responsive className="mt-12">
                    <thead>
                        <tr>
                            <th>Country</th>
                            <th>Total</th>
                            <th>Total %</th>
                            <th>Male</th>
                            <th>Female</th>
                            <th>Other</th>
                        </tr>
                    </thead>
                    <tbody>
                    {statistics.statistic.map((e)=>(
                        <tr key={e.country +'-'+ e.count}>
                            <td><Flag className='country-flag cursor-pointer' code={e?.country} title={getCountryName(e?.country)}/></td>
                            <td>{e.count}</td>
                            <td>{e.percentage}%</td>
                            <td>{e.maleCount} ({calculatePercentage(+e.maleCount,+e.count)})%</td>
                            <td>{e.femaleCount} ({calculatePercentage(+e.femaleCount,+e.count)})%</td>
                            <td>{e.undefinedCount} ({calculatePercentage(+e.undefinedCount,+e.count)})%</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}
