import * as React from "react";
import { useEffect, useState } from "react";
import { IUser } from "../../../model/user/IUser";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Table,
} from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { AgencyService } from "../AgencyService";
import { toast } from "react-toastify";
import { Pagination } from "../../../shared/components/Pagination";
import { useHistory } from "react-router";
import { ImpersonateEye } from "../../../shared/components/ImpersonateEye";
import Flag from "react-world-flags";
import {getCountryName} from "../../../helpers/CorrectCountryNames";

export function ListAgency() {
  let history = useHistory();

  const [state, setState] = useState({
    perPage: 15,
    page: 1,
    displayName: "",
    email: "",
  });

  const [user, setUser] = useState<IUser[]>([]);
  const [change, setChange] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    AgencyService.getAgencies(state)
      .then((data) => {
        setUser(data.data);
        setTotalPages(Math.ceil(data.count / state.perPage));
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`);
      });
    return () => {};
  }, [change]);

  // function disableUsers(id: number) {
  //   AgencyService.disableUser(id)
  //     .then((data) => {
  //       let test = [...user];
  //       const found = test.find((x) => x.id === id);
  //       const index = found ? test.indexOf(found) : 0;
  //       test[index] = data;
  //       setUser(test);
  //     })
  //     .catch((err) => {});
  // }

  function passData(route: string, data: any) {
    history.push({
      pathname: route,
      state: data,
    });
  }

  function handleClick() {
    setChange(!change);
  }

  const handlePages = (updatePage: number) => {
    setState({ ...state, page: updatePage });
    setChange(!change);
  };

  function handleChange(evt: any) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value?.trim(),
    });
  }

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() =>
          history.push(`/agency/create`, { id: undefined, isEdit: false })
        }
      >
       + Add agency user
      </Button>

      <div className="card card-custom mb-5 p-4 mt-3" onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClick();
            setState({...state, page: 1})
          }
        }}>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Control placeholder="Id" className='autocomplete-input' name="id" onChange={handleChange} />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Control placeholder="Display name" className='autocomplete-input' name="displayName" onChange={handleChange} />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Control placeholder="Email" className='autocomplete-input' name="email" onChange={handleChange} />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Control placeholder="Has Existing?" className='autocomplete-input' name="hasExisting"
                          onChange={handleChange} as="select">
                          <option value="undefined">Has Existing?</option>
                          <option value="true">Existing in pending</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <div>
          <Button
              type="submit"
              onClick={() => {
                handleClick();
                setState({...state, page: 1})
              }}
          >
            Search
          </Button>
        </div>
      </div>

      <div className="card card-custom mb-5 p-4">
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Display name</th>
              <th>Creator Email</th>
              <th>Country</th>
              <th>Action</th>
              <th>Pending emails</th>
              <th>Managers</th>
              <th className="text-center">Influencers</th>
            </tr>
          </thead>
          <tbody>
            {user?.map((e) => (
                <tr key={e.id}>
                  <td>{e.id}</td>
                  <td>
                    {e.displayName}
                    <br/>
                    {e?.createdBy?.firstName} {e?.createdBy?.lastName}
                  </td>
                  <td><ImpersonateEye id={e?.createdBy?.id ?? 0}/>{e?.createdBy?.email}</td>
                  <td><Flag className='country-flag cursor-pointer' code={e?.contact?.country}
                            title={getCountryName(e.contact.country)}/></td>
                  <td>
                    <DropdownButton
                        alignRight
                        title={<FaUser/>}
                        id="dropdown-menu-align-right"
                    >
                      <Dropdown.Item
                          eventKey="1"
                          onClick={() =>
                              passData("/agency/edit", {id: e.id, isEdit: true})
                          }
                      >
                        Edit
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                  <td>
                    <Button
                        onClick={() =>
                            history.push(`/agency/pending-emails`, {id: e.id})
                        }
                    >
                      Emails ({e?.invitedInfluencers?.length ?? 0})
                    </Button>
                  </td>
                  <td>
                    <Button
                        onClick={() =>
                            history.push(`/agency/managers/${e.id}`)
                        }
                    >
                      Managers ({e?.managersCount ?? 0})
                    </Button>
                  </td>
                  <td>
                    <Button
                        onClick={() =>
                            history.push(`/agency/influencers/${e.id}`)
                        }
                    >
                      Influencers ({e?.influencersCount ?? 0})
                    </Button>
                  </td>
                </tr>
            ))}
          </tbody>
          {user.length < 15 && !!user.length && (
              <div className='h-180px'></div>
          )}
        </Table>
        <div className="w-100">
          {totalPages <= 1 ? (
              ""
          ) : (
              <Pagination
                  page={state.page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </>
  );
}
