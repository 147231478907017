import React, {useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router';
import {Company} from '../../../model/company/Company';
import {CompanyService} from '../CompanyService';
import {Button, Card, Col, Form, Image} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {multipartFormData} from '../../../services';
import {COMPANY_URL} from '../../../utils/Endpoints';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {getCorrectEnums} from "../../../utils/Functions";
import {calculateFee} from "../../../helpers/FeeCaluculation";

type TCompanyForm = Omit<Partial<Company>, "updatedAt" | "createdAt" | "profilePhoto">;

export function CompanyForm() {
    const history = useHistory();
    const {state: {id, isEdit}}: any = useLocation();
    const {country , CompanyEmployeesEnum ,CompanyIndustryEnum } = useSelector((state: any) => state.enums);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImage] = useState('');
    const [company, setCompany] = useState<TCompanyForm>({
        id: 0,
        email: '',
        industry: '',
        nbEmployees: '',
        displayName: '',
        about: '',
        createdBy: 0,
        fee: 0.75,
        packageExpiration: '',
        externalSearchTokens: 0,
        contact: {
            phone: '',
            street: '',
            city: '',
            province: '',
            zip: '',
            country: '',
            uidNumber: '',
            companyName: '',
            companyRegister: '',
            smallBusiness: false,
            invoiceFirstName: '',
            invoiceLastName: ''
        },
        bank: {
            accountBankName: '',
            accountIban: '',
            accountSwift: '',
            accountPaymentType: ''
        },
        package: {},
        packageOverride:{},
        isSupplier: false,
    });

    useEffect(() => {
        if (isEdit) {
            CompanyService.getCompany(id).then((data) => {
                if (data.id) {
                    setCompany({...company, ...data});
                    setImage(data.profilePhoto?.path)
                }
            });
        }
    }, [])
    
    function editCreateCompany() {
        multipartFormData(selectedFile, isEdit ? `${COMPANY_URL}/${id}` : COMPANY_URL, JSON.stringify(company), isEdit ? 'put' : 'post')
            .then(() => {
                SuccessToast( isEdit ? 'Successfully updated company' : 'Successfully created company')
                history.goBack();
            }).catch(error => ErrorToast(error))
    }

    function handleChange(evt: any, isContact: boolean = false, isBank: boolean = false) {
        const {value, name} = evt.target;
        if (isContact) {
            setCompany({
                ...company,
                contact: {
                    ...company?.contact!,
                    [name]: value
                }
            })
        } else if (isBank) {
            setCompany({
                ...company,
                bank: {
                    ...company?.bank!,
                    [name]: value
                }
            })
        } else {
            setCompany({
                ...company,
                [name]: name === "externalSearchTokens" && value > 100 ? 100 : value
            });
        }
    }

    function handleCheckbox(evt: any, obj?: string) {
        const {name, checked} = evt.target;

        if (!!obj) {
            setCompany({
                ...company,
                [obj]: {
                    ...company?.[obj as keyof TCompanyForm],
                    [name]: checked
                }
            })
        } else {
            setCompany({
                ...company,
                [name]: checked
            })
        }
    }

    function handleFileInput(evt: any) {
        if (evt.target.files.length > 0) {
            setSelectedFile(evt.target.files[0]);
            setImage(URL.createObjectURL(evt.target.files[0]))
        }
    }

    return (
        <>
            <Card className="card card-custom mb-5">
                <Form as={Card.Body}>
                    <h3>Personal info</h3>
                    <hr className=''/>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Display name</Form.Label>
                            <Form.Control name="displayName" onChange={handleChange}
                                          defaultValue={company?.displayName}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Fee Currently: {calculateFee(company.fee)}</Form.Label>
                            <Form.Control name="fee" disabled={true} onChange={handleChange} value={company?.fee}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group className="col-md-6">
                            <Form.Label>Industry</Form.Label>
                            <Form.Control name="industry" as="select" onChange={handleChange}
                                          value={company?.industry ?? ''}>
                                {CompanyIndustryEnum?.map((industry: string) => <option key={industry} value={industry}>{getCorrectEnums(industry)}</option>)}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>External search tokens</Form.Label>
                            <Form.Control max={100} type="number" name="externalSearchTokens" onChange={handleChange}
                                          value={company?.externalSearchTokens}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className="col-md-6">
                            <Form.Label>Employees</Form.Label>
                            <Form.Control name="nbEmployees" as="select" onChange={handleChange}
                                          value={company?.nbEmployees ?? ''}>
                                {CompanyEmployeesEnum?.map((employee: string) => <option key={employee} value={employee}>{getCorrectEnums(employee)}</option>)}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="col-md-6 d-flex">
                            {imagePath &&
                                <Image width={70} height={70} alt={imagePath} src={imagePath}/>
                            }
                            <div className="ml-2">
                                <Form.Label>Upload photo</Form.Label>
                                <input
                                    type="file"
                                    onChange={handleFileInput}
                                />
                            </div>
                        </Form.Group>
                    </Form.Row>
                    
                    <Form.Row>
                        <Form.Group className="col-md-6 d-flex mb-0">
                            <Form.Check onChange={(e) => handleCheckbox(e)} name="isSupplier" type="checkbox" label="Is Supplier"
                                            checked={company?.isSupplier}/>
                        </Form.Group>
                        <Form.Group className='col-6 mb-0'>
                            <Form.Check label='Transparent Pricing' type="checkbox" name="isTransparentPricing"
                                        onChange={(e:any) => handleCheckbox(e)}
                                        checked={company.isTransparentPricing}/>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card>

            {/*CONTACT*/}
            <Card className="card card-custom mb-5">
                <Form as={Card.Body}>
                    <h3>Contact</h3>
                    <hr />
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control name="phone" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.phone ?? ''}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Street, House Number, Apartment Number</Form.Label>
                            <Form.Control name="street" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.street ?? ''}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control name="zip" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.zip ?? ''}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>City</Form.Label>
                            <Form.Control name="city" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.city ?? ''}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>State</Form.Label>
                            <Form.Control name="province" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.province ?? ''}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Country</Form.Label>
                            <Form.Control name="country" as="select" onChange={e => (handleChange(e, true))}
                                          value={company?.contact?.country ?? ''}>
                                {country.map((country: string) => <option key={country}>{country}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Company name</Form.Label>
                            <Form.Control name="companyName" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.companyName ?? ''}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Company register</Form.Label>
                            <Form.Control name="companyRegister" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.companyRegister ?? ''}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Package expiration</Form.Label>
                            <Form.Control type="date" placeholder="expiration" className="autocomplete-input" name="packageExpiration" onChange={e => (handleChange(e))}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Vat number</Form.Label>
                            <Form.Control name="uidNumber" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.uidNumber ?? ''}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Invoice firstname</Form.Label>
                            <Form.Control name="invoiceFirstName" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.invoiceFirstName ?? ''}/>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Invoice lastname</Form.Label>
                            <Form.Control name="invoiceLastName" onChange={e => (handleChange(e, true))}
                                          defaultValue={company?.contact?.invoiceLastName ?? ''}/>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} className="mt-3 mb-0" id="formGridCheckbox">
                            <Form.Check name="smallBusiness" onChange={(e) => handleCheckbox(e, 'contact')} type="checkbox"
                                        label="Small business" checked={company?.contact?.smallBusiness}/>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card>

            {/*BANK*/}
            <Card className="card-custom mb-5">
                <Form as={Card.Body}>
                    <h4>Bank</h4>
                    <hr/>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Account bank name</Form.Label>
                            <Form.Control name="accountBankName" onChange={e => (handleChange(e, false, true))}
                                          defaultValue={company?.bank?.accountBankName ?? ''}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Account iban</Form.Label>
                            <Form.Control name="accountIban" onChange={e => (handleChange(e, false, true))}
                                          defaultValue={company?.bank?.accountIban ?? ''}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} className='mb-0'>
                            <Form.Label>Account swift</Form.Label>
                            <Form.Control name="accountSwift" onChange={e => (handleChange(e, false, true))}
                                          defaultValue={company?.bank?.accountSwift ?? ''}/>
                        </Form.Group>
                        <Form.Group as={Col} className='mb-0'>
                            <Form.Label>Account payment type</Form.Label>
                            <Form.Control name="accountPaymentType" onChange={e => (handleChange(e, false, true))}
                                          defaultValue={company?.bank?.accountPaymentType ?? ''}/>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card>
            <Button onClick={editCreateCompany} size="lg" variant="primary">
                Save
            </Button>
        </>
    )
}
