import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Table} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {toast} from 'react-toastify';
import {ICampaignsTypes} from '../../../model/campaign/ICampaignType';
import {Pagination} from '../../../shared/components/Pagination';
import {CampaignsService} from '../CampaignsService';

export function CampaignType() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        title: '',
    })

    const [campaignTypes, setCampaignTypes] = useState<ICampaignsTypes[]>([])
    const [change, setChange] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        CampaignsService.getCampaignsTypes(state)
            .then((data) => {
                setCampaignTypes(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
        return () => {
        };
    }, [change, page]);


    function handleClick(event: any) {
        event.preventDefault()
        setChange(!change)
    }

    function handleChange(event: any) {
        const value = event.target.value.trim();
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    function handleCheckbox(id: number) {
        CampaignsService.campaignTypeActive(id)
    }

    return (
        <>

            <Button variant="outline-primary"
                    onClick={() => history.push('/campaign/types/form', {id: undefined, isEdit: false})}>
                + Add campaign type
            </Button>

            <div className="card mb-5 p-4 mt-3"
                 onKeyDown={(e) => {
                     if (e.key === "Enter") handleClick(e);
                 }}>
                <Form.Row>
                    <Form.Group as={Col} className='col-4 mb-0'>
                        <Form.Control placeholder='Title' className='autocomplete-input' name="title"
                                      onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group as={Col} className='mb-0'>
                        <Button type="submit" onClick={handleClick}>
                            Search
                        </Button>
                    </Form.Group>
                </Form.Row>
            </div>

            <div className="card card-custom mb-5 p-4">
                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Followers range from</th>
                        <th>Followers range to</th>
                        <th>Active</th>
                        <th>Image</th>
                        <th>Edit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {campaignTypes?.map((type) => (
                        <tr key={type.id}>
                            <td>{type.id}</td>
                            <td>{type.title}</td>
                            <td>{type.description.length > 40 ? type.description.substring(0, 39) + '...' : type.description ?? '-'}</td>
                            <td>{type.followersRangeFrom}</td>
                            <td>{type.followersRangeTo}</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <input className='form-check-input' type="checkbox" name="isActive"
                                           onChange={(event) => {
                                               handleCheckbox(type.id!)
                                           }}
                                           defaultChecked={type.isActive}
                                           data-toggle="toggle"
                                    />
                                    <p className="m-0 pl-2">Active</p>
                                </div>
                            </td>
                            <td>
                                {type.creatorTypeImage ?
                                    <img alt='' src={type?.creatorTypeImage?.thumbnail} style={{width: 70, height: 60}}
                                         className="rounded img-cover"/>
                                    : <img alt='' src="/images/placeholder.png" style={{width: 70, height: 60}}
                                           className="rounded"/>}
                            </td>
                            <td>
                                <Button
                                    onClick={() => history.push(`/campaign/types/form`, {id: type.id, isEdit: true})}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>

            </div>

        </>
    )
}
