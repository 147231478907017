import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Image} from "react-bootstrap";
import {ErrorToast, SuccessToast, WarningToast} from "../../../utils/toasters";
import {CampaignsService} from "../CampaignsService";
import {useHistory, useLocation} from "react-router-dom";
import {multipartFormData} from "../../../utils/api.service";
import {CAMPAIGN_TYPES_URL} from "../../../utils/Endpoints";
import {ICampaignType} from "../../../model/campaign/ICampaignType";


export function CampaignTypeForm() {
    const [type, setType] = useState<ICampaignType>({
        title: '',
        description: '',
        descriptionGerman: '',
        followersRangeFrom: 0,
        followersRangeTo: 0,
        isActive: false
    });
    const {state: {id, isEdit}}: any = useLocation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImage] = useState('')
    const [check, setCheck] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (isEdit) {
            CampaignsService.getCampaignType(id)
                .then((response) => {
                    setType(response);
                    response?.creatorTypeImage && setImage(response?.creatorTypeImage?.path);
                    setCheck(response.isActive);
                }).catch(err => ErrorToast(err))
        }
    }, [])

    useEffect(() => {
        setType({...type, isActive: check})
    }, [check])


    const createCampaignType = (e: any) => {
        e.preventDefault();
        if ((type.title !== "") && (type.description !== "") && (type.followersRangeFrom !== 0) && (type.followersRangeTo !== 0)) {
            multipartFormData(selectedFile, CAMPAIGN_TYPES_URL, JSON.stringify(type), 'post')
                .then(response => {
                    SuccessToast('Successfully created campaign type');
                    history.push("/campaign/types");
                }).catch(error => ErrorToast(error))
        } else {
            WarningToast('Please fill out all mandatory fields');
        }

    }
    const updateCampaignType = (e: any) => {
        e.preventDefault();
        multipartFormData(selectedFile, `${CAMPAIGN_TYPES_URL}/${id}`, JSON.stringify(type))
            .then(response => {
                SuccessToast('Successfully updated campaign type');
                history.push("/campaign/types");
            }).catch(error => ErrorToast(error))
    }

    function handleChange(event: any) {
        const value = event.target.value;
        setType({
            ...type,
            [event.target.name]: value
        });
    }

    function handleFileInput(evt: any) {
        if (evt.target.files.length > 0) {
            setSelectedFile(evt.target.files[0]);
            setImage(URL.createObjectURL(evt.target.files[0]))
        }
    }

    return (
        <Card>
            <Form as={Card.Body}>
                <h3> {isEdit ? 'Update type' : 'Create type'}</h3>
                <hr/>
                <Form.Row>
                    <Col md={8}>
                        <Form.Group>
                            <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                            <Form.Control id='campaignTypeInput' name="title" onChange={handleChange}
                                            defaultValue={type.title}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description<span className="text-danger">*</span></Form.Label>
                            <Form.Control id='campaignTypeInput' as='textarea' maxLength={255} name="description"
                                            onChange={handleChange}
                                            defaultValue={type.description}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description German<span className="text-danger">*</span></Form.Label>
                            <Form.Control id='campaignTypeInput' as='textarea' maxLength={255} name="descriptionGerman"
                                            onChange={handleChange}
                                            defaultValue={type.descriptionGerman}/>
                        </Form.Group>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Followers range from<span className="text-danger">*</span></Form.Label>
                                    <Form.Control id='campaignTypeInput' type="number" name="followersRangeFrom"
                                                    onChange={handleChange}
                                                    value={type.followersRangeFrom}/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Followers range to<span className="text-danger">*</span></Form.Label>
                                    <Form.Control id='campaignTypeInput' type="number" name="followersRangeTo"
                                                    onChange={handleChange}
                                                    value={type.followersRangeTo}/>
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Group>
                            <Form.Check label="active" type="checkbox" name="active" onChange={() => setCheck(!check)}
                                        checked={check}/>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Upload photo</Form.Label>
                            <Form.Control accept=".jpg, .jpeg, .png" type="file" onChange={handleFileInput}/>
                            {imagePath &&
                                <Image className='mt-3 img-cover' width={300} height={250} alt={imagePath} src={imagePath}/>
                            }
                        </Form.Group>
                    </Col>

                </Form.Row>
                <Button onClick={isEdit ? updateCampaignType : createCampaignType} className="col-md-1 col-sm-12 "
                        size='lg' variant="primary"
                        type="submit">
                    Save
                </Button>
            </Form>
        </Card>
    )
}
