import React from 'react';
import {IUser} from '../../../model/user/IUser';
import {Card, Table} from 'react-bootstrap';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {formatDate, getCorrectEnums} from '../../../utils/Functions';
import Flag from "react-world-flags";
import {getCountryName} from "../../../helpers/CorrectCountryNames";

export function LatestUsersList({users}: {users: IUser[]}){
    return (
        <Card className="p-4 mt-3 col-md-5-5 col-sm-12">
            <h5 className='p-3'>Latest Users</h5>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>Display name</th>
                        <th>Type</th>
                        <th>Country</th>
                        <th className='text-right'>Joined</th>
                    </tr>
                </thead>
                <tbody>
                {users?.map((e)=>(
                    <tr key={e.id}>
                        <td className='align-items-center d-flex'><ImpersonateEye style='py-0 h-15-px d-flex' id={e.id ?? 0}/>{e.firstName}</td>
                        <td>{getCorrectEnums(e?.userType!)}</td>
                        <td><Flag className='country-flag cursor-pointer h-100' code={e?.contact?.country} title={getCountryName(e.contact.country)}/></td>
                        <td className="text-right">{formatDate(e.createdAt)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Card>
    )
}
