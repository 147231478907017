import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCorrectEnums } from "../../../utils/Functions";
import { IReportItem } from "../../../model/report/Report-item";

interface IModalReject {
    showModal: boolean,
    setModalData: (args: any) => void,
    rejectReport: (updatedReport: IReportItem) => void;
    report: IReportItem,
}

export default function ModalReportReject({ showModal, setModalData, rejectReport, report }: IModalReject) {
    const { rejectReportEnum } = useSelector((state: any) => state.enums);
    const [selectedReason, setSelectedReason] = useState(report.additionalPost ? report?.rejectReason : report?.event?.rejectReason);

    useEffect(() => {
        setSelectedReason(report.additionalPost ? report?.rejectReason : report?.event?.rejectReason);
    }, [report]);

    return (
        <Modal
            show={showModal}
            onHide={() => setModalData((prev: any) => ({ ...prev, showModal: false }))}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Reject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="rejectReason">
                        <Form.Label>Select Rejection Reason</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                        >
                            <option value="">-- Select Rejection Reason --</option>
                            {rejectReportEnum && Object.entries(rejectReportEnum).map(([key, value]) => (
                                <option key={key} value={String(value)}>{getCorrectEnums(String(value))}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => setModalData((prev: any) => ({ ...prev, showModal: false }))}>
                    Close
                </Button>
                <Button
                    variant="danger"
                    onClick={() => rejectReport({ ...report, rejectReason: selectedReason })}
                    disabled={!selectedReason}
                >
                    Reject
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
