import {IMPERSONATE_LINK} from './Endpoints';
import moment from 'moment';

export function impersonationUsers(token: string, id: number, currentRoute: string) {
    window.location.replace(`${IMPERSONATE_LINK}/impersonate/${token}/${id}/true${currentRoute}`);
}

export const formatDate = (date?: string) => {
    return moment(date)?.format('DD.MM.YYYY.');
};

export function hasAccessRight(acl: string) {
    let aclArr = '';
    aclArr = localStorage.getItem("adminAcl") ?? ''
    return aclArr.includes(acl)
}

export const getCorrectEnums = (param: string) => {
    let res = '';
    if (param.includes("_")) {
        const arrWords = param.split('_');
        arrWords.map((item: string) => (
            res = res + ' ' + item.charAt(0).toUpperCase() + item.slice(1)
        ))
    } else {
        res = param.charAt(0).toUpperCase() + param.slice(1);
    }

    return res;
};

export const getFlagEmoji = (countryCode: string): string | null => {
    if (countryCode) {
        const codePoints = countryCode
            .toUpperCase()
            .split('')
            .map(char => 127397 + char.charCodeAt(0));
        return String.fromCodePoint(...codePoints);
    } else {
        return null;
    }
};

export const getCorrectSocialUrl = (name: string, handle: string, externalLink?: string) => {
    switch (name) {
        case 'instagram':
            return 'https://instagram.com/' + handle;
        case 'youtube':
            return 'https://youtube.com/@' + handle;
        case 'facebook':
            return 'https://facebook.com/' + handle;
        case 'tiktok':
            return 'https://tiktok.com/@' + handle;
        case 'twitter':
            return 'https://twitter.com/' + handle;
        case 'twitch':
            return 'https://twitch.com/' + handle;
        case 'podcast':
        case 'blog':
        case 'linkedin':
            return externalLink;
        default:
            return null;
    }
};

export const getPlatformValue = (platform: string): string => {
    switch (platform) {
        case "instagram":
        case "instagram_post":
            return "IG Post";
        case "instagram_story":
            return "IG Story";
        case "instagram_reels":
            return "IG Reel";
        case "instagram_tv":
            return "IG TV";
        case "facebook":
            return "Facebook Post";
        case "facebook_live":
            return "Facebook Live";
        case "facebook_story":
            return "Facebook Story";
        case "tiktok":
            return "TT Post";
        case "tiktok_story":
            return "TT Story";
        case "podcast":
            return "Podcast";
        case "youtube":
            return "YT Video";
        case "youtube_shorts":
            return "YT Short";
        case "twitter":
        case "twitter_post":
            return "X Post";
        case "blog":
            return "Blog Post";
        case "twitch":
            return "Twitch";
        case "linkedin":
            return "LI Post";
        default:
            return "";
    }
};

