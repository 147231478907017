import {requests} from '../../services';
import {BLOG_URL} from '../../utils/Endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {IBlog} from '../../model/blog/IBlog';

export const BlogService = {
    getBlogs: (params: any): Promise<BaseModel<IBlog>> => requests.get(`${BLOG_URL}`,params),
    editBlog: (id: number, blog: IBlog): Promise<IBlog> => requests.put(`${BLOG_URL}/${id}`, blog),
    getBlog: (id: number): Promise<IBlog> => requests.get(`${BLOG_URL}/${id}`),
    createBlog: (blog: any): Promise<IBlog> => requests.post(BLOG_URL, blog),
    deleteBlog: (id: number) => requests.delete(`${BLOG_URL}/${id}`)
}
