import React, {useState, useEffect} from 'react';
import {Modal, Form, Button, Card} from 'react-bootstrap';
import {IPassword, IUser} from '../../../../model/user/IUser';
import {UserService} from '../../UserService';
import {Pagination} from '../../../../shared/components/Pagination';
import {UserFilter} from '../UserFilter';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/all';
import InfluencerGeneralTable from './InfluencerGeneralTable';
import InfluencerOnboardingTable from './InfluencerOnboarding';
import InfluencerTabs from './InfluencerTabs';

export const InfluencerList = () => {
    let history = useHistory();

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [passState, setPassState] = useState<IPassword>({
        password: '',
        repeatPassword: ''
    });
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        displayName: '',
        email: '',
        firstName: '',
        lastName: '',
        type: '',
        count: 0,
    })
    const [selectedUser, setSelectedUser] = useState<IUser | null>();
    const [user, setUser] = useState<IUser[]>([]);
    const [change, setChange] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [isPasswordHidden, setIsPasswordHidden] = useState(false);
    const [table, setTable] = useState<'general' | 'onboarding'>('general');

    useEffect(() => {
        UserService.getInfluencers(state)
            .then((data) => {
                setUser(data?.data);
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err?.response?.data?.message}`)
        });
    }, [change]);

    function disableUsers(id: number) {
        UserService.disableUser(id)
            .then((data) => {
                const updatedUsers = user.map((prev) => {
                    if(prev?.id! === id) return data
                    return prev
                })
                setUser(updatedUsers);
                const status = !!data.isEnabled ? 'enabled' : 'disabled';
                SuccessToast('Succesfully '+status+' user')
            })
            .catch((err) => ErrorToast(err));
    }

    const toggleOnboarding = (id: number) => {
        UserService.onboardUser(id)
            .then((data) => {
                const updatedUsers = user.map((prev) => {
                    if(prev?.id! === id) return data
                    return prev
                })
                setUser(updatedUsers);
                SuccessToast('Successfully action')
            })
            .catch((err) => ErrorToast(err));
    }

    function suspendUser(id: number) {
        UserService.suspendUser(id)
            .then((data) => {
                const updatedUsers = user.map((prev) => {
                    if(prev?.id! === id) return data
                    return prev
                })
                setUser(updatedUsers);
                SuccessToast('Succesfully action')
            })
            .catch((err) => ErrorToast(err));
    }

    function passData(route: string, data: any) {
        history.push({
            pathname: route,
            state: data
        })
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage});
        setChange((prev) => !prev);
    };

    function handleClick() {
        setState((prev) => {return {...prev, page: 1}})
        setChange((prev) => !prev);
    }

    function handleChange(evt: React.ChangeEvent<HTMLInputElement>, isSelectBoolean?: boolean) {
        const {name, value} = evt.target;

        let newValue: any = value;
        if (typeof newValue === 'string') {
            newValue = value.replace(/^@/, '');
        }
        if ( isSelectBoolean ) {
            if ( value === "null" ) {
                newValue = undefined;
            } else newValue = value === "enable" ? true : false
        }
        
        setState({
            ...state,
            [name]: newValue // remove @ on first place
        });
    }

    const changePasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setPassState(prev => ({...prev, [name]: value}))
    }

    const handleCloseModal = () => {
        setShowChangePassword(false)
        setSelectedUser(null)
    }

    const submitPasswordHandler = async () => {
        if (passState?.password === passState?.repeatPassword) {
            await UserService.changePassword(selectedUser?.id as number, passState).then(response => {
                if (response?.id) {
                    SuccessToast(`You have successfully changed password for user ID ${response?.id}`)
                    handleCloseModal();
                }
            }).catch(error => ErrorToast(error));
        } else {
            WarningToast('Provided passwords are not the same')
        }
    }

    // const editSupervisor = (id: number) => {
    //     UserService.updateSupervisor(id)
    //     .then((data) => {
    //         const updatedUsers = user.map((prev) => {
    //             if(prev?.id! === id) return data
    //             return prev
    //         })
    //         setUser(updatedUsers);
    //         SuccessToast('Successfully action')
    //     })
    //     .catch((err) => ErrorToast(err));
    // }

    return (
        <div>
            <h3 className="text-dark fw-bold fs-2 mb-4">Influencer list</h3>

            <Card className="mb-5 p-4" onKeyDown={(e: any) => {if(e.key === 'Enter') handleClick()}}>
                <UserFilter
                    handleChanges={handleChange}
                    handleClicks={handleClick}
                />
            </Card>
            
            <InfluencerTabs table={table} setTable={setTable} state={state}/>

            <Card className="mb-5 p-4">
                {table === 'general' ? (
                    <InfluencerGeneralTable 
                        user={user} 
                        passData={passData} 
                        setShowChangePassword={setShowChangePassword} 
                        setSelectedUser={setSelectedUser} 
                        disableUsers={disableUsers} 
                        suspendUser={suspendUser}
                    /> 
                ) : (
                    <InfluencerOnboardingTable 
                        user={user} 
                        passData={passData} 
                        toggleOnboarding={toggleOnboarding} 
                        setShowChangePassword={setShowChangePassword} 
                        setSelectedUser={setSelectedUser} 
                        suspendUser={suspendUser} 
                        disableUsers={disableUsers}
                    />
                )}

                {totalPages > 1 &&
                    <div className="w-100">
                            <Pagination
                                page={state.page}
                                totalPages={totalPages}
                                handlePagination={handlePages}
                            />
                    </div>
                }
            </Card>

            <Modal
                show={showChangePassword}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Change password #{selectedUser?.id}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>New password<span className="text-danger">*</span></Form.Label>
                        <div className="d-flex align-items-center">
                            <Form.Control as="input" onChange={changePasswordHandler} value={passState?.password ?? ''}
                                          type={!isPasswordHidden ? 'password' : 'text'}
                                          name="password"/>
                            <span style={{marginLeft: '-2rem'}} className="cursor-pointer"
                                    onClick={() => setIsPasswordHidden(prev => !prev)}>
                                {isPasswordHidden ? <AiFillEye size={15}/> : <AiFillEyeInvisible size={15}/>}
                            </span>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Repeat password<span className="text-danger">*</span></Form.Label>
                        <Form.Control as="input" onChange={changePasswordHandler}
                                      value={passState?.repeatPassword ?? ''} type="password"
                                      name="repeatPassword"/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">Close</Button>
                    <Button variant="primary" onClick={submitPasswordHandler}
                            disabled={passState?.password?.length < 7}>Change</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
