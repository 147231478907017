import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";

export function Brand() {
  return (
      <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12`}
      >
        <Link to="" className="text-center">
          <img
            alt="logo"
            src={toAbsoluteUrl("/images/logo.jpeg")}
            style={{width: '80%'}}
          />
        </Link>
      </div>
  );
}
