import React, { useEffect, useState} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {IBlog} from '../../../model/blog/IBlog';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {BlogService} from '../BlogService';
import {useLocation, useHistory} from 'react-router';
import { Editor} from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import  { ContentState, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {useDropzone} from 'react-dropzone';
import {ADDED_BLOG, UPDATED_BLOG} from '../../../enum/Messages';
import {LocalRoutes} from '../../../enum/LocalRoutes';

const initialBlog: IBlog = {
    author: '',
    category: '',
    createdAt: '',
    id: 0,
    intro: '',
    isEnabled: false,
    locale: '',
    metaDescription: '',
    metaKeywords: '',
    slug: '',
    text: '',
    title: '',
    updatedAt: '',
    video: '',
    viewCountPlural: 0,
    viewCountSingular: 0,
    mainImage: {},
}

export function BlogForm() {

    const {country, blogCategory} = useSelector((state: any) => state.enums);
    const editor = EditorState

    const history = useHistory()
    const {state}: any = useLocation();
    const {id, isEdit} = state;


    const [blog, setBlog] = useState<IBlog>(initialBlog)
    const [file, setFile] = useState<any>([]);
    const  [editorState, setEditorState] = useState<any>(editor.createEmpty());

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            // @ts-ignore
            setFile(acceptedFiles?.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = file?.map((file:any) => (
        <div className="thumb" key={file.name}>
            <img
                src={file.thumb ? file.thumb : file.preview}
                className="img"
                alt=''
            />
        </div>
    ));

    useEffect(()=>{
        if (isEdit){
            BlogService.getBlog(id).then((data) => {
                if (data.id) {
                    setBlog(data);
                    if(data.mainImage) {
                        const test = [];
                        test.push(data.mainImage)
                        setFile(test);
                    }
                    const contentBlock = htmlToDraft(data.text);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(
                            contentBlock.contentBlocks
                        )
                        const  newEditorState = EditorState.createWithContent(contentState);
                        setEditorState(newEditorState)
                    }
                }
            }).catch((err) => {
                toast.error(`${err.message}`)
            });
        }
    },[])

    function editBlog(env: any) {
        env.preventDefault()
        BlogService.editBlog(id, blog).then((data)=>{
            setBlog(data)
            toast(UPDATED_BLOG)
        }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    }

    function createBlog(env: any) {
        env.preventDefault()
        const formData = new FormData();
        formData.append('file', file[0]);
        for(let key in blog){
            // @ts-ignore
            formData.append(key, blog[key]);
        }

        blog.title.length < 3 ?
            toast.error("Title must be 3 characters long")
            : BlogService.createBlog(formData).then((data)=>{
            if (data.id) {
                history.push(LocalRoutes.BLOG_LIST)
            }
            toast(ADDED_BLOG)
        }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    }

    function handleChange(evt: any) {
        const {name, value} = evt.target;
        setBlog({
            ...blog,
            [name]: value
        });
    }

    function handleCheckbox(evt: any) {
        const change = String(evt.target.checked);
        setBlog({
            ...blog,
            [evt.target.name]: change
        });
    }

    const onChangeEditor = (editorState: any) => {
        setEditorState(editorState)
        const contentState = editorState.getCurrentContent();
        const html = convertToHTML(contentState);
        setBlog({
            ...blog,
            text: html
        })
    }

    return (
        <div className="card card-custom mb-5 p-4">
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control name="title" onChange={handleChange} defaultValue={blog?.title}/>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>Category</Form.Label>
                        <Form.Control name="category" onChange={handleChange} as="select" value={blog?.category}>
                            {blogCategory?.map((category: string)=><option key={category}>{category}</option>)}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Author</Form.Label>
                        <Form.Control name="author" onChange={handleChange} defaultValue={blog?.author}/>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Label>Video</Form.Label>
                        <Form.Control name="video" onChange={handleChange} defaultValue={blog?.video}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Locale</Form.Label>
                        <Form.Control name="locale" onChange={handleChange} as="select" value={blog?.locale}>
                            {country?.map((country: string) => <option key={country}>{country}</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Meta keywords</Form.Label>
                        <Form.Control name="metaKeywords" onChange={handleChange} defaultValue={blog?.metaKeywords}></Form.Control>
                    </Form.Group>

                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Meta description</Form.Label>
                        <Form.Control  name="metaDescription" onChange={handleChange} defaultValue={blog?.metaDescription}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Intro</Form.Label>
                        <Form.Control as="textarea" rows={4} name="intro" onChange={handleChange} defaultValue={blog?.intro}/>
                    </Form.Group>
                </Form.Row>

                <div className='editor-container'>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={onChangeEditor}
                        editorStyle={{ border: '1px solid lightgray',paddingLeft: 20,paddingRight: 10,marginBottom: 10, minHeight: 500 }}
                        toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                    />
                </div>

                <Form.Group className="mt-3" id="formGridCheckbox">
                    <Form.Check onChange={handleCheckbox} name="isEnabled" type="checkbox" label="Enabled" checked={blog?.isEnabled} />
                </Form.Group>

                <section className="container mt-5 mb-5">
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                    {thumbs}
                </section>

                <Button onClick={isEdit ? editBlog : createBlog} size='lg' variant="primary" type="submit">
                    Save
                </Button>
            </Form>
        </div>
    )
}


