import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Image} from "react-bootstrap";
import {ErrorToast, SuccessToast, WarningToast} from "../../../utils/toasters";
import {CampaignsService} from "../CampaignsService";
import {ICreateCampaignGoal} from "../../../model/campaign/ICampaignsGoals";
import {useHistory, useLocation} from "react-router-dom";
import {multipartFormData} from "../../../utils/api.service";
import {CAMPAIGN_GOALS_URL} from "../../../utils/Endpoints";

export function CampaignGoalForm() {
    const [goal, setGoal] = useState<ICreateCampaignGoal>({
        title: '',
        story: '',
        summary: '',
        isActive: false,
    });
    const {state: {id, isEdit}}: any = useLocation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImage] = useState('')
    const [check, setCheck] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (isEdit) {
            CampaignsService.getCampaignGoal(id)
                .then((response) => {
                    setGoal(response);
                    response?.goalImage && setImage(response?.goalImage?.path)
                    setCheck(response.isActive)
                }).catch(err => ErrorToast(err))
        }
    }, [])

    useEffect(() => {
        setGoal({...goal, isActive: check})
    }, [check])

    const createCampaignGoal = () => {
        if ((goal.title !== "") && (goal.summary !== "") && (goal.story !== "")) {
            multipartFormData(selectedFile, CAMPAIGN_GOALS_URL, JSON.stringify(goal), 'post')
                .then(response => {
                    SuccessToast('Successfully created campaign goal')
                    history.push("/campaign/goals")
                }).catch(error => ErrorToast(error))
        } else {
            WarningToast('Please fill out all mandatory fields');
        }
    }
    const updateCampaignGoal = () => {
        multipartFormData(selectedFile, `${CAMPAIGN_GOALS_URL}/${id}`, JSON.stringify(goal))
            .then(response => {
                SuccessToast('Successfully updated campaign goal')
                history.push("/campaign/goals")
            }).catch(error => ErrorToast(error))
    }

    function handleChange(event: any) {
        const value = event.target.value;
        setGoal({
            ...goal,
            [event.target.name]: value
        });
    }

    function handleFileInput(evt: any) {
        if (evt.target.files.length > 0) {
            setSelectedFile(evt.target.files[0]);
            setImage(URL.createObjectURL(evt.target.files[0]))
        }
    }

    return (
        <Card className="mb-5">
            <Form as={Card.Body}>
                <h3> {isEdit ? 'Update goal' : 'Create goal'}</h3>
                <hr/>
                <Form.Row>
                    <Col md={8}>
                        <Form.Group>
                            <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                            <Form.Control name="title" onChange={handleChange} defaultValue={goal.title}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Summary<span className="text-danger">*</span></Form.Label>
                            <Form.Control as='textarea' maxLength={255} name="summary" onChange={handleChange}
                                            defaultValue={goal.summary}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Story<span className="text-danger">*</span></Form.Label>
                            <Form.Control as='textarea' maxLength={255} name="story" onChange={handleChange}
                                            defaultValue={goal.story}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check label="active" type="checkbox" name="active" onChange={() => setCheck(!check)}
                                        checked={check}/>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Upload photo</Form.Label>
                            <Form.Control accept=".jpg, .jpeg, .png" type="file" onChange={handleFileInput}/>
                            {imagePath &&
                                <Image className='mt-3 img-cover' width={300} height={250} alt={imagePath} src={imagePath}/>
                            }
                        </Form.Group>
                    </Col>

                </Form.Row>
                <Button onClick={isEdit ? updateCampaignGoal : createCampaignGoal} className="col-md-1 col-sm-12 "
                        size='lg' variant="primary">
                    Save
                </Button>
            </Form>
        </Card>
    )
}
