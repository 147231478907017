import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

interface ISupervisorFilter {
  handleChange: (...args: any) => void;
  setTriggerSupervisor: (...args: any) => void;
  setParams: (...args: any) => void;
}

export default function SupervisorFilter({ handleChange, setTriggerSupervisor, setParams }: ISupervisorFilter) {
  return (
          <div className="card mb-5 p-4 mt-3"
               onKeyDown={(e) => {
                   if (e.key === "Enter") {
                       setTriggerSupervisor((prev: boolean) => !prev);
                       setParams((prev: any) => ({...prev, page: 1}))
                   }
               }}>
              <Form.Row>
                  <Form.Group as={Col} className='col-4 mb-0'>
                      <Form.Control placeholder='Firstname' className='autocomplete-input' name="name"
                                    onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group as={Col} className='mb-0'>
                      <Button onClick={() => {
                          setTriggerSupervisor((prev: boolean) => !prev)
                          setParams((prev: any) => ({...prev, page: 1}))
                      }}>
                          Search
                      </Button>
                  </Form.Group>
              </Form.Row>
          </div>
  );
}
