import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {CampaignsService} from '../CampaignsService';
import {Campaign} from '../../../model/campaign/Campaign';
import AutocompleteInput from '../../../shared/components/AutocompleteInput';
import {downloadFileRequest} from "../../../utils/api.service";
import {CAMPAIGN_BUDGET_DOWNLOAD_URL} from "../../../utils/Endpoints";
import {FaTimes} from "react-icons/fa";

interface IOfferFilter {
    handleState: (...arg: any) => void;
    setState: (...arg: any) => void;
    budgetFilter: any
}

export function BudgetOverviewFilter({handleState, setState, budgetFilter}: IOfferFilter) {
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [campaignFilter, setCampaignFilter] = useState("");

    useEffect(() => {
        CampaignsService.getCampaigns({perPage: 15, title: campaignFilter, isLongTerm: false, approvalStatus:'approved'})
            .then((data) => {
                setCampaigns(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
    }, [campaignFilter]);

    function handleChanges(evt: any, ) {
        const value = evt.target.value;
        setState({
            ...budgetFilter,
            [evt.target.name]: value,
        });
    }

    return(
        <Form>
            <Row>
                <div className="form-group col-md-6 col-sm-12">
                    <AutocompleteInput placeholder='Campaign title or id'
                                       onSelect={setState}
                                       setFilter={setCampaignFilter}
                                       keyState={'id'}
                                       keyItemSelect={'id'}
                                       value={campaignFilter}
                                       items={campaigns}
                                       hasClearBtn
                                       renderItems={['id', 'title']}
                                       style='zindex-5'
                                       clearFunc={() => {
                                           setState((prev: any) => ({...prev, id: undefined}))
                                           setCampaignFilter('');
                                       }}
                    />
                </div>
                <div className="form-group  col-md-2 col-sm-12">
                    <Form.Control placeholder='hasApplicationDeadlinePassed' className='autocomplete-input'
                                  name="hasApplicationDeadlinePassed"
                                  onChange={handleChanges} as="select">
                        <option value="">Application deadline?</option>
                        <option value="false">Passed deadline</option>
                        <option value="true">Not Passed deadline</option>
                    </Form.Control>
                </div>
                <div className="form-group col-md-2 col-sm-12">
                    <Form.Control placeholder='hasSpending' className='autocomplete-input'
                                  name="hasSpending"
                                  onChange={handleChanges} as="select">
                        <option value="">Spending?</option>
                        <option value="true">Has spending</option>
                        <option value="false">No spending</option>
                    </Form.Control>
                </div>
                <div>
                    <Form.Control placeholder="Packages" className='autocomplete-input' name="packageId"
                                  onChange={handleChanges} as="select">
                        <option value=''>All Packages</option>
                        <option value='1'>Starter</option>
                        <option value='2'>Pro</option>
                        <option value='3'>Full Service</option>
                        <option value='4'>Client</option>
                        <option value='5'>Agency</option>
                    </Form.Control>
                </div>
                <div className="form-group col-md-auto col-sm-12 ">
                    <label htmlFor="from">Deadline from:</label>
                    <div className='d-flex bg-secondary-o-100'>
                    <Form.Control className='autocomplete-input cursor-pointer' type='date' name="from" value={budgetFilter?.from || ''}
                                  onChange={handleChanges}/>
                    {budgetFilter?.from && (
                        <Button variant="" className='py-2 px-3 clearBtn btn-hover-bg-secondary' onClick={() => {
                            setState((prev: any) => ({...prev, from: undefined}))
                        }}>
                            <FaTimes fontSize={14}/>
                        </Button>
                    )}
                    </div>
                </div>
                <div className="form-group col-md-auto col-sm-12">
                    <label htmlFor="to">Deadline to:</label>
                    <div className='d-flex bg-secondary-o-100'>
                        <Form.Control className='autocomplete-input cursor-pointer' type='date' name="to"
                                      value={budgetFilter?.to || ''}
                                      onChange={handleChanges}/>
                        {budgetFilter?.to && (
                            <Button variant="" className='py-2 px-3 clearBtn btn-hover-bg-secondary' onClick={() => {
                                setState((prev: any) => ({...prev, to: undefined}))
                            }}>
                                <FaTimes fontSize={14}/>
                            </Button>
                        )}
                    </div>
                </div>
            </Row>
            <Button className="mt-4 mr-3" variant="primary" onClick={() => handleState(budgetFilter)}>
                Search
            </Button>
            <Button className='mt-4' variant="outline-primary"
                    onClick={() => downloadFileRequest(CAMPAIGN_BUDGET_DOWNLOAD_URL, 'export-budget', null, budgetFilter)}>
                Export current
            </Button>
        </Form>
    )
}
